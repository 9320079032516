import React, { useEffect, useState } from 'react';
import moment from 'moment';
// MUI components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
// Icons
import { DotsThree } from '@phosphor-icons/react';
// Custom components
import ImagePlaceholder from 'components/Image/ImagePlaceholder';
// Constants
import durationOptions from "constants/duration";

function CourseClass(props) {
    const { data, updateClass, deleteClass, editable } = props;

    const theme = useTheme();

    const [duration, setDuration] = useState(0);
    const [classDate, setClassDate] = useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const renderClassImage = (imgSrc) => {
        if(!imgSrc) return <ImagePlaceholder height={55} width={55} iconSize={22} />;
        return (
            <img
                src={imgSrc}
                alt="Class thumbnail"
                style={{ width: 55, height: 55, objectFit: 'cover' }}
            />
        );
    }

    useEffect(() => {
        const classStartMoment = moment(data.business_class_start, 'YYYY-MM-DD HH:mm:ss');
        const classEndMoment = moment(data.business_class_end, 'YYYY-MM-DD HH:mm:ss');
        const durationMinutes = classEndMoment.diff(classStartMoment, 'minutes');
        setDuration(durationOptions.find(x => x.value === durationMinutes)?.label);
        setClassDate(classStartMoment.format('dddd Do MMM, YYYY, HH:mm'));
    }, [data.business_class_start, data.business_class_end]);

    return (
        <Box sx={{ border: '1px solid lightgray', borderRadius: 1, mt: 2, gap: 1.5, padding: 1.5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1.5} alignItems="center">
                    {renderClassImage(data.business_class_img)}
                    <Box>
                        <Typography sx={{ fontSize: 18, fontWeight: '600' }}>{data.business_class_name}</Typography>
                        <Typography variant="subtitle2">{classDate} | {duration}</Typography>
                    </Box>
                </Stack>
                <div>
                    <IconButton
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <DotsThree weight="bold" size={20} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { updateClass(data.business_course_module_id, data.business_class_id); handleClose(); }}>{editable ? 'Edit' : 'View'}</MenuItem>
                        {editable && (<MenuItem sx={{ color: theme.palette.error.main }} onClick={() => { deleteClass(data.business_course_module_id, data.business_class_id); handleClose(); }}>Delete</MenuItem>)}
                    </Menu>
                </div>
            </Stack>
        </Box>
    );
}

export default CourseClass;