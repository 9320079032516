import React, { useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// Icons
import { CaretDown, TrashSimple } from '@phosphor-icons/react';

function CourseOptions(props) {
    const { onSave, onDelete, loading, editable, deletable } = props;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Box>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <LoadingButton 
                        variant="contained"
                        onClick={onSave}
                        loading={loading}
                        disabled={!editable}
                    >
                        Save
                    </LoadingButton>
                    {deletable && (
                        <Button
                            id="header-options-menu-button"
                            size="small"
                            aria-controls={open ? 'header-options-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="Course options"
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                            <CaretDown weight="bold" size={14}/>
                        </Button>
                    )}
                </ButtonGroup>
                {deletable && (
                    <Menu
                        id="header-options-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{ 'aria-labelledby': 'header-options-menu-button' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem sx={{ gap: 1, color: 'error.main' }} onClick={onDelete}>
                            <TrashSimple weight="bold" size={14} />
                            Delete
                        </MenuItem>
                    </Menu>
                )}
            </Box>
        </React.Fragment>
    );
}

export default CourseOptions;