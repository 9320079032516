import CurrencyInput from "react-currency-input-field";
// MUI components
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function CurrencyField(props) {

    const { symbol, label, value, onChange, disabled } = props;

    return (
        <CurrencyInput
            customInput={TextField}
            label={label || "Price"}
            value={(value / 100).toFixed(2)}
            placeholder="0.00"
            decimalsLimit={2}
            decimalScale={2}
            fixedDecimalLength={2}
            allowNegativeValue={false}
            disabled={disabled}
            step={1}
            InputProps={{
                startAdornment: <InputAdornment position="start">{symbol || "SYMBOL"}</InputAdornment>,
            }}
            onValueChange={(_, __, { float }) => onChange(float * 100)}
        />
    );
}