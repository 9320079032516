import React, { useState, useEffect } from 'react';

// MUI components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
// Gneric components
import Modal from 'components/Material/Modal';

function CourseModuleForm(props) {
    const { isVisible, setVisible, submitModule, moduleObj } = props;

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [description, setDescription] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(moduleObj) {
            setName(moduleObj.business_course_module_name);
            setDescription(moduleObj.business_course_module_description);
        }
    }, [moduleObj]);

    const nameMaxLength = 255;
    const descriptionMaxLength = 1000;

    const submitForm = () => {
        if (loading) return;
        if(!name || !name.trim()) {
            setNameError(true);
            return;
        }
        setLoading(true);
        submitModule({
            business_course_module_id: moduleObj?.business_course_module_id,
            business_course_module_name: name,
            business_course_module_description: description?.length > 0 ? description : null
        });
    }

    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>{moduleObj?.business_course_module_id ? 'Edit' : 'Add'} Module</Typography>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    label="Module Name"
                    variant="outlined"
                    required
                    helperText={nameMaxLength - (name?.length || 0)}
                    FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                    inputProps={{
                        maxLength: nameMaxLength
                    }}
                    disabled={loading}
                    error={nameError}
                />
                <TextField
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    helperText={descriptionMaxLength - (description?.length || 0)}
                    FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                    inputProps={{
                        maxLength: descriptionMaxLength
                    }}
                    disabled={loading}
                />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={submitForm}
                >
                    {moduleObj?.business_course_module_id ? 'Update' : 'Add'}
                </LoadingButton>
            </Box>
        </Modal>
    );
}

export default CourseModuleForm;