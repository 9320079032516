import { useState } from 'react';
// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
// Custom components
import Modal from '../Material/Modal';


function ConfirmDeleteModal(props) {
    const { isVisible, setVisible, onConfirm, loading, dataEntityName } = props;

    const [dataEntityValue, setDataEntityValue] = useState('');
    const [error, setError] = useState(false);

    const handleConfirm = () => {
        if(loading) return;
        if (dataEntityValue === dataEntityName) {
            setError(false);
            onConfirm();
            return;
        }
        setError(true);
    }

    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Stack direction="column" spacing={4}>
                    <Typography variant="h5">Are you sure?</Typography>
                    <TextField
                        label={`Type "${dataEntityName}" to confirm`}
                        value={dataEntityValue}
                        sx={{ mt: 2 }}
                        onChange={(e) => setDataEntityValue(e.target.value)}
                        error={error}
                        helperText={error ? "Value doesn't match" : ''}
                    />
                    <LoadingButton variant="contained" color="error" loading={loading} onClick={handleConfirm}>
                        Delete
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    );
}

export default ConfirmDeleteModal;