import React from "react";

// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import {
    Grid,
    Button,
    CircularProgress
} from "@mui/material";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Card from "components/Material/Card";
import StaffPermissionsGrid from "components/Staff/StaffPermissionsGrid";
// Redux
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// Utilities
import withRouter from "utilities/withRouter";

import Api from '../../api/index';

class Permissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            permissions: null
        };
    }

    componentDidMount() {
        if(this.props.userRole.permission.update === false) {
            const { navigate } = this.props;
            this.props.triggerNotification("You don't have access to edit permissions.", "danger", "bc", 4);
            setTimeout(() => navigate("/admin/home"), 10);
            return;
        }
        this.getPermissions();
    }

    async getPermissions() {
        this.setState({ loading: true });
        try {
            const { data } = await Api.getPermissions();
            this.setState({ permissions: data.userRoleData });
        } catch(e) {
            this.props.triggerNotification("An error occured while retrieving your permissions.", "danger", "bc", 6);
        }
        this.setState({ loading: false });
    }

    handlePermissionClick = (roleId, entity, key, value) => {
        const permissionsIndex = this.state.permissions?.findIndex(x => x.business_user_role_type_id === roleId);
        if(permissionsIndex > -1) {
            let permissions = [...this.state.permissions];
            permissions[permissionsIndex][entity][key] = value;
            this.setState({ permissions });
        }
    }

    async savePermissions() {
        if(this.state.loading) {
            return;
        }
        this.setState({ loading: true });
        const permissions = [...this.state.permissions].filter(x => x.business_user_role_type_id !== 1);
        try {
            await Api.postPermissions({ permissions });
            this.props.triggerNotification("Permissions updated successfully.", "success", "bc", 4);
        } catch(e) {
            this.props.triggerNotification("An error occured while saving your permissions.", "danger", "bc", 6);
        }
        this.setState({ loading: false });
    }

    renderPermissionGrids() {
        const entities = [
            "location",
            "booking",
            "break",
            "service",
            "staff",
            "hours",
            "product",
            "gallery",
            "customer",
            "billing",
            "report",
            "date",
            "setting",
            "notification",
            "waitlist",
            "course",
            "permissions"
        ];
        return (
            <>
                {entities.map((entity) =>
                    <Card>
                        <StaffPermissionsGrid
                            entity={entity}
                            permissions={this.state.permissions}
                            onCheckboxClick={this.handlePermissionClick}
                            loading={this.state.loading}
                        />
                    </Card>
                )}
            </>
        )
    }

    render() {
        if(this.props.userRole.permission.update === false) {
            return null;
        }
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Permissions</h2>
                        </Col>
                    </Row>
                }
            />
                <div className="content">
                    <Grid container>
                        <Grid item xl={6} lg={8} md={10} sm={12} sx={{ mx: 'auto' }}>
                            {this.renderPermissionGrids()}
                            <Button
                                variant="contained"
                                onClick={() => this.savePermissions()}
                            >
                                {this.state.loading ? (
                                    <CircularProgress
                                        color="secondary"
                                        size={25}
                                    />
                                ) : 'Save' }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        userRole: state.userRole
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Permissions));