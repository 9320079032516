import * as types from "../constants/actionTypes";
import { beginAjaxCall, errorAjaxCall } from './ajaxStatus';
import Api from '../api/index';

// Loading Spinner

export function loadLoadingSuccess(isLoading) { return { type: types.LOAD_LOADING_SUCCESS, isLoading }};

export function loadLoadingSpinner(isLoading) {
    return function (dispatch) {
        dispatch(loadLoadingSuccess(isLoading));
    };
};

// User

export function loadUserSuccess(user) {
    user.last_refresh = new Date().getTime();
    return { type: types.LOAD_USER_SUCCESS, user }
};

export function loadUser(user) {
    return function (dispatch) {
        dispatch(loadUserSuccess(user));
        // Load selected business location if available
        let selectedBusinessLocationId = user.selected_business_location_id;
        if(selectedBusinessLocationId && selectedBusinessLocationId > 0){
            dispatch(loadSettingsSuccess({ selected_business_location_id: selectedBusinessLocationId }));
        }
        // Load calendar settings if available
        let userCalendarSettings = user.calendar_settings;
        if(userCalendarSettings) {
            try {
                userCalendarSettings = JSON.parse(userCalendarSettings);
                dispatch(loadCalendarSettingsSuccess(userCalendarSettings));
            } catch(e) {
                console.log('Unable to parse calendar settings');
            }
        }
    };
};

export function loadUserAuto() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getUser().then(f => {
            return f.data;
        }).then((result) => {
            dispatch(loadUserSuccess(result.userData));
            dispatch(loadUserRoleSuccess(result.userRole));
            // Load calendar settings if available
            let userCalendarSettings = result.userData.calendar_settings;
            if(userCalendarSettings) {
                try {
                    userCalendarSettings = JSON.parse(userCalendarSettings);
                    dispatch(loadCalendarSettingsSuccess(userCalendarSettings));
                } catch(e) {
                    console.log('Unable to parse calendar settings');
                }
            }
            // Load selected business location if available
            let selectedBusinessLocationId = result.userData?.selected_business_location_id;
            if(selectedBusinessLocationId > 0){
                dispatch(loadSettingsSuccess({ selected_business_location_id: selectedBusinessLocationId }));
            }
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function updateUserSuccess(user) { return { type: types.UPDATE_USER_SUCCESS, user }};

export function updateUser(user) {
    return function (dispatch) {
        dispatch(updateUserSuccess(user));
    };
};

export function clearUserSuccess() { return { type: types.CLEAR_USER_SUCCESS, user: [] }};

export function clearUser(user) {
    return function (dispatch) {
        dispatch(clearUserSuccess());
    };
};

// User Role

export function loadUserRoleSuccess(userRole) { return { type: types.LOAD_USER_ROLE_SUCCESS, userRole }};

export function loadUserRole(userRole) {
    return function (dispatch) {
        dispatch(loadUserRoleSuccess(userRole));
    };
};

export function clearUserRole() {
    return function (dispatch) {
        dispatch(loadUserRoleSuccess([]));
    };
};

// Business

export function loadBusinessSuccess(business) {
    business.last_refresh = new Date().getTime();
    return { type: types.LOAD_BUSINESS_SUCCESS, business }
};

export function loadBusiness(business) {
    return function (dispatch) {
        dispatch(loadBusinessSuccess(business));
    };
};

export function loadBusinessAuto() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getBusiness().then(f => {
            return f.data;
        }).then((result) => {
            let businessData = result.businessData;
            dispatch(loadBusinessSuccess(businessData));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function updateBusinessSuccess(business) { return { type: types.UPDATE_BUSINESS_SUCCESS, business }};

export function updateBusiness(business) {
    return function (dispatch) {
        dispatch(updateBusinessSuccess(business));
    };
};

export function clearBusinessSuccess() { return { type: types.CLEAR_BUSINESS_SUCCESS, business: {} }};

export function clearBusiness() {
    return function (dispatch) {
        dispatch(clearBusinessSuccess());
    };
};

// Business Locations

export function loadBusinessLocationSuccess(businessLocation) { return { type: types.LOAD_BUSINESS_LOCATION_SUCCESS, businessLocation }};
export function loadBusinessLocationStaffMapSuccess(businessLocationStaffMap) { return { type: types.LOAD_BUSINESS_LOCATION_STAFF_MAP_SUCCESS, businessLocationStaffMap }};

export function loadBusinessLocations(businessLocationData) {
    return function (dispatch) {
        dispatch(loadBusinessLocationSuccess(businessLocationData));
    };
};

export function loadBusinessLocationsAuto(globalSettings = null, userData = null) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getBusinessLocations().then(f => {
            return f.data;
        }).then((result) => {
            // Populate location data
            let businessLocationData = result.businessLocationData;
            dispatch(loadBusinessLocationSuccess(businessLocationData));
            // Populate location staff map data
            let businessLocationStaffMapData = result.businessLocationStaffMapData;
            dispatch(loadBusinessLocationStaffMapSuccess(businessLocationStaffMapData));
            // Populate selected business location
            if(globalSettings && userData) {
                let settings = Object.assign({}, globalSettings);
                if(Number.isInteger(userData?.selected_business_location_id)) {
                    if(userData?.selected_business_location_id !== globalSettings?.selected_business_location_id) {
                        settings.selected_business_location_id = userData.selected_business_location_id;
                    }
                } else {
                    settings.selected_business_location_id = businessLocationData[0].business_location_id;
                }
                // Update settings
                if(settings.selected_business_location_id !== globalSettings.selected_business_location_id) {
                    dispatch(loadSettingsSuccess(settings));
                }
                // Update user
                if(settings.selected_business_location_id !== userData.selected_business_location_id) {
                    Api.postSelectedBusinessLocation(settings);
                    let userDataCopy = Object.assign({}, userData);
                    userDataCopy.selected_business_location_id = settings.selected_business_location_id;
                    dispatch(updateUserSuccess(userDataCopy));
                }
            }
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function addBusinessLocationSuccess(businessLocation) { return { type: types.ADD_BUSINESS_LOCATION_SUCCESS, businessLocation }};

export function addBusinessLocation(businessLocation) {
    return function (dispatch) {
        dispatch(addBusinessLocationSuccess(businessLocation));
    };
};

export function updateBusinessLocationSuccess(businessLocation) { return { type: types.UPDATE_BUSINESS_LOCATION_SUCCESS, businessLocation }};

export function updateBusinessLocation(businessLocation) {
    return function (dispatch) {
        dispatch(updateBusinessLocationSuccess(businessLocation));
    };
};

export function deleteBusinessLocationSuccess(businessLocation) { return { type: types.DELETE_BUSINESS_LOCATION_SUCCESS, businessLocation }};

export function deleteBusinessLocation(businessLocationId) {
    return function (dispatch) {
        dispatch(deleteBusinessLocationSuccess(businessLocationId));
    };
};

export function loadBusinessLocationStaffMap(businessLocationData) {
    return function (dispatch) {
        dispatch(loadBusinessLocationStaffMapSuccess(businessLocationData));
    };
};

export function clearBusinessLocation() {
    return function (dispatch) {
        dispatch(loadBusinessLocationSuccess([]));
        dispatch(loadBusinessLocationStaffMapSuccess([]));
    };
};

// Staff

export function loadStaffSuccess(staff) { return { type: types.LOAD_STAFF_SUCCESS, staff }};

export function loadStaff() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getStaff().then(f => {
            return f.data;
        }).then((result) => {
            dispatch(loadStaffSuccess(result.staffData));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function clearStaffSuccess() { return { type: types.CLEAR_STAFF_SUCCESS, staff: [] }};

export function clearStaff() {
    return function (dispatch) {
        dispatch(clearStaffSuccess());
    };
};

// To Do

export function loadToDoSuccess(toDo) { return { type: types.LOAD_TO_DO_SUCCESS, toDo }};

export function loadToDo(toDo) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getToDo().then(f => {
            return f.data.toDoData;
        }).then((result) => {
            dispatch(loadToDoSuccess(result));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function updateToDoSuccess(toDo) { return { type: types.UPDATE_TO_DO_SUCCESS, toDo }};

export function updateToDo(toDo) {
    return function (dispatch) {
        dispatch(updateToDoSuccess(toDo));
    };
};

export function addToDoSuccess(toDo) { return { type: types.ADD_TO_DO_SUCCESS, toDo }};

export function addToDo(toDo) {
    return function (dispatch) {
        dispatch(addToDoSuccess(toDo));
    };
};

export function deleteToDoSuccess(toDo) { return { type: types.DELETE_TO_DO_SUCCESS, toDo }};

export function deleteToDo(toDo) {
    return function (dispatch) {
        dispatch(deleteToDoSuccess(toDo));
    };
};

export function clearToDoSuccess() { return { type: types.CLEAR_TO_DO_SUCCESS, toDo: [] }};

export function clearToDo() {
    return function (dispatch) {
        dispatch(clearToDoSuccess());
    };
};

// Calendar Settings

export function loadCalendarSettingsSuccess(calendar_settings) { return { type: types.LOAD_CALENDAR_SETTINGS_SUCCESS, calendar_settings }};

export function loadCalendarSettings(calendar_settings) {
    return function (dispatch) {
        dispatch(loadCalendarSettingsSuccess(calendar_settings));
        if(Object.keys(calendar_settings)?.length > 0) {
            Api.postCalendarSettings(calendar_settings);
        }
    };
};

// Settings

export function loadSettingsSuccess(settings) { return { type: types.LOAD_SETTINGS_SUCCESS, settings }};

export function loadSettings(settings, userData) {
    return function (dispatch) {
        dispatch(loadSettingsSuccess(settings));
        if(Object.keys(settings)?.length > 0 && settings.selected_business_location_id > 0) {
            Api.postSelectedBusinessLocation(settings);
            if(userData && userData.selected_business_location_id !== settings.selected_business_location_id) {
                let userDataCopy = Object.assign({}, userData);
                userDataCopy.selected_business_location_id = settings.selected_business_location_id;
                dispatch(updateUserSuccess(userDataCopy));
            }
        }
    };
};

// Service Category

export function loadServiceCategorySuccess(serviceCategory) { return { type: types.LOAD_SERVICE_CATEGORY_SUCCESS, serviceCategory }};

export function loadServiceCategory() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getServiceCategories().then(f => {
            return f.data.serviceCategoryData;
        }).then((result) => {
            dispatch(loadServiceCategorySuccess(result));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function clearServiceCategorySuccess() { return { type: types.CLEAR_SERVICE_CATEGORY_SUCCESS, serviceCategory: [] }};

export function clearServiceCategory() {
    return function (dispatch) {
        dispatch(clearServiceCategorySuccess());
    };
};

// Service

export function loadServiceSuccess(service) { return { type: types.LOAD_SERVICE_SUCCESS, service }};
export function loadServiceDetailSuccess(serviceDetail) { return { type: types.LOAD_SERVICE_DETAIL_SUCCESS, serviceDetail }};
export function loadServiceStaffMapSuccess(serviceStaffMap) { return { type: types.LOAD_SERVICE_STAFF_MAP_SUCCESS, serviceStaffMap }};
export function loadServiceLocationMapSuccess(serviceLocationMap) { return { type: types.LOAD_SERVICE_LOCATION_MAP_SUCCESS, serviceLocationMap }};

export function loadService() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getServices().then(f => {
            return f.data;
        }).then((result) => {
            dispatch(loadServiceSuccess(result.serviceData));
            dispatch(loadServiceDetailSuccess(result.serviceDetailData));
            dispatch(loadServiceStaffMapSuccess(result.serviceStaffMapData));
            dispatch(loadServiceLocationMapSuccess(result.serviceLocationMapData));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};

export function clearService() {
    return function (dispatch) {
        dispatch(loadServiceSuccess([]));
        dispatch(loadServiceDetailSuccess([]));
        dispatch(loadServiceStaffMapSuccess([]));
        dispatch(loadServiceLocationMapSuccess([]));
    };
};

// Service Detail


// Product

export function loadProductSuccess(product) { return { type: types.LOAD_PRODUCT_SUCCESS, product }};

export function loadProduct(product) {
    return function (dispatch) {
        dispatch(loadProductSuccess(product));
    };
};

export function loadProductAuto() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Api.getProducts().then(f => {
            return f.data;
        }).then((result) => {
            dispatch(loadProductSuccess(result.productData));
        }).catch(error => {
            dispatch(errorAjaxCall());
            console.log(error);
        });
    };
};