import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// Cookies
import { CookiesProvider } from 'react-cookie';
// Material provider
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store/index';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.4.0";
import "assets/css/demo.css";
// Layouts
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// Custom error fallback component
import ErrorFallback from "components/Generic/ErrorFallback";
// Material theme
import theme from "assets/material/theme";

let obj = configureStore();
let store = obj.store;
let persistor = obj.persistor;

if(process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://69f5cc7f13784db681546edc425adcfb@o805225.ingest.sentry.io/5893413",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

// Set true viewport unit
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const errorFallback = <ErrorFallback/>
const root = createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={errorFallback} showDialog>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="admin/*"
                    element={<AdminLayout/>}
                  />
                  <Route
                    path="auth/*"
                    element={<AuthLayout/>}
                  />
                  <Route path="*" element={<Navigate to="auth/login-page" replace/>} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </CookiesProvider>
);
