import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Input,
    FormGroup,
    FormText
} from "reactstrap";
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import CardMap from "components/Maps/CardMap.js";
import ConfirmDeleteModal from "components/ConfirmDeleteModal/ConfirmDeleteModal";
// Redux
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// API
import Api from 'api/index';
// Constants
import currency from '../../../constants/currency';
import timezone from '../../../constants/timezone';
import country from '../../../constants/country';
import { CDN_URL } from "constants/urls";
// Utilities
import customValidation from 'utilities/customValidation';
// Decorators
import withRouter from "utilities/withRouter";

class BusinessLocationEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            businessLocationData: null,
            businessLocationId: null,
            businessLocationName: null,
            businessLocationUnit: null,
            businessLocationStreetNumber: null,
            businessLocationStreetName: null,
            businessLocationCity: null,
            businessLocationState: null,
            businessLocationPostcode: null,
            businessLocationCountry: null,
            businessLocationLat: null,
            businessLocationLng: null,
            businessLocationEmail: null,
            businessLocationContactNumber: null,
            businessLocationContactNumberIso: null,
            deleteModalVisible: false,
            deleteModalLoading: false,
            deletable: this.props?.userRole?.location?.delete ?? false
        };
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleLatLngChange = this.handleLatLngChange.bind(this);
    };

    componentDidMount() {
        try {
            const query = new URLSearchParams(window.location.search);
            let business_location_id = query.get("business_location_id");
            if(business_location_id) {
                business_location_id = parseInt(business_location_id);
                if(Number.isInteger(business_location_id)){
                    this.loadBusinessLocation(business_location_id);
                } else {
                    this.props.navigate('/admin/business-locations');
                }
            } else {
                this.props.navigate('/admin/business-locations');
            }
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst loading the location.", "danger", "bc", 4);
            console.log(err);
        }
    }

    loadBusinessLocation(business_location_id) {
        let businessLocationObj = this.props.businessLocation.filter(x => x.enabled === 1).find(x => x.business_location_id === business_location_id);
        if(businessLocationObj) {
            this.setState({
                businessLocationData: businessLocationObj,
                businessLocationId: businessLocationObj.business_location_id,
                businessLocationName: businessLocationObj.business_location_name,
                businessLocationUnit: businessLocationObj.address_unit_name,
                businessLocationStreetNumber: businessLocationObj.address_street_number,
                businessLocationStreetName: businessLocationObj.address_street_name,
                businessLocationCity: businessLocationObj.address_city,
                businessLocationState: businessLocationObj.address_state,
                businessLocationPostcode: businessLocationObj.address_postal_code,
                businessLocationCountry: businessLocationObj.address_country,
                businessLocationLat: businessLocationObj.address_latitude ? parseFloat(businessLocationObj.address_latitude) : null,
                businessLocationLng: businessLocationObj.address_longitude ? parseFloat(businessLocationObj.address_longitude) : null,
                businessLocationEmail: businessLocationObj.contact_email,
                businessLocationContactNumber: businessLocationObj.contact_number,
                businessLocationContactNumberIso: businessLocationObj.contact_number_iso,
            });
        } else {
            this.props.triggerNotification("Location not found", "danger", "bc", 4);
            this.props.navigate('/admin/business-locations');
        }
    }

    handleChange(e, name) {
        switch(name) {
            case "name":
                this.setState({ businessLocationName: e.target.value });
                break;
            case "unit":
                this.setState({ businessLocationUnit: e.target.value });
                break;
            case "streetNumber":
                this.setState({ businessLocationStreetNumber: e.target.value });
                break;
            case "streetName":
                this.setState({ businessLocationStreetName: e.target.value });
                break;
            case "city":
                this.setState({ businessLocationCity: e.target.value });
                break;
            case "state":
                this.setState({ businessLocationState: e.target.value });
                break;
            case "postCode":
                this.setState({ businessLocationPostcode: e.target.value });
                break;
            case "country":
                this.setState({ businessLocationCountry: e.label });
                break;
            case "email":
                this.setState({ businessLocationEmail: e.target.value });
                break;
            case "contactNumber":
                this.setState({ businessLocationContactNumber: e[0], businessLocationContactNumberIso: e[1].countryCode });
                break;
            default:
                return;
        }
    }

    handleAddressChange(placeObj) {
        if(!placeObj.address_components) {
            return;
        }
        let businessLocationStreetNumber = placeObj.address_components.find(x => x.types.includes('street_number'));
        let businessLocationStreetName = placeObj.address_components.find(x => x.types.includes('route'));
        let businessLocationCity = placeObj.address_components.find(x => x.types.includes('postal_town'));
        let businessLocationState = placeObj.address_components.find(x => x.types.includes('administrative_area_level_2'));
        let businessLocationPostcode = placeObj.address_components.find(x => x.types.includes('postal_code'));
        let businessLocationCountry = placeObj.address_components.find(x => x.types.includes('country'));
        this.setState({
            businessLocationUnit: null,
            businessLocationStreetNumber: businessLocationStreetNumber ? businessLocationStreetNumber.long_name : null,
            businessLocationStreetName: businessLocationStreetName ? businessLocationStreetName.long_name : null,
            businessLocationCity: businessLocationCity ? businessLocationCity.long_name : null,
            businessLocationState: businessLocationState ? businessLocationState.long_name : null,
            businessLocationPostcode: businessLocationPostcode ? businessLocationPostcode.long_name : null,
            businessLocationCountry: businessLocationCountry ? businessLocationCountry.long_name : null,
            businessLocationLat: placeObj.geometry.location.lat(),
            businessLocationLng: placeObj.geometry.location.lng()
        });
    }

    handleLatLngChange(lat, lng) {
        this.setState({
            businessLocationLat: lat,
            businessLocationLng: lng
        });
    }

    async submitBusinessLocation() {
        if(!this.state.businessLocationName || this.state.businessLocationName.length === 0) {
            this.props.triggerNotification("A location name is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationStreetName || this.state.businessLocationStreetName.length === 0) {
            this.props.triggerNotification("Address incomplete - a street name is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationCity || this.state.businessLocationCity.length === 0) {
            this.props.triggerNotification("Address incomplete - a city is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationState || this.state.businessLocationState.length === 0) {
            this.props.triggerNotification("Address incomplete - a state/county is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationPostcode || this.state.businessLocationPostcode.length === 0) {
            this.props.triggerNotification("Address incomplete - a postcode is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationCountry || this.state.businessLocationCountry.length === 0) {
            this.props.triggerNotification("Address incomplete - a country is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationEmail || this.state.businessLocationEmail.length === 0) {
            this.props.triggerNotification("An email address is required.", "danger", "bc", 4);
            return;
        }
        if(!customValidation.validateEmail(this.state.businessLocationEmail)) {
            this.props.triggerNotification("Invalid email address.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationContactNumber || this.state.businessLocationContactNumber.length === 0) {
            this.props.triggerNotification("A contact number is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLocationLat || !this.state.businessLocationLng) {
            this.props.triggerNotification("Unable to determine business address. Please search for your address and ensure that your location is displayed correctly on the map.", "danger", "bc", 10);
            return;
        }
        this.props.actions.loadLoadingSpinner(true);
        try {
            let contact_number = null;
            if(this.state.businessLocationContactNumber && this.state.businessLocationContactNumber.length > 0) {
                if(this.state.businessLocationContactNumber.charAt(0) !== '+') {
                    contact_number = '+' + this.state.businessLocationContactNumber;
                } else {
                    contact_number = this.state.businessLocationContactNumber;
                }
            }
            await Api.updateBusinessLocation({
                business_location_id: this.state.businessLocationId,
                business_location_name: this.state.businessLocationName,
                business_location_unit: this.state.businessLocationUnit,
                business_location_street_number: this.state.businessLocationStreetNumber,
                business_location_street_name: this.state.businessLocationStreetName,
                business_location_city: this.state.businessLocationCity,
                business_location_state: this.state.businessLocationState,
                business_location_postcode: this.state.businessLocationPostcode,
                business_location_country: this.state.businessLocationCountry,
                business_location_latitude: Number(parseFloat(this.state.businessLocationLat).toFixed(7)),
                business_location_longitude: Number(parseFloat(this.state.businessLocationLng).toFixed(7)),
                business_location_email: this.state.businessLocationEmail,
                business_location_contact_number: contact_number,
                business_location_contact_number_iso: this.state.businessLocationContactNumberIso,
            });
            // Update Redux state
            let businessLocationObj = {};
            businessLocationObj.business_location_id = this.state.businessLocationId;
            businessLocationObj.business_location_name = this.state.businessLocationName;
            businessLocationObj.address_unit_name = this.state.businessLocationUnit;
            businessLocationObj.address_street_number = this.state.businessLocationStreetNumber;
            businessLocationObj.address_street_name = this.state.businessLocationStreetName;
            businessLocationObj.address_city = this.state.businessLocationCity;
            businessLocationObj.address_state = this.state.businessLocationState;
            businessLocationObj.address_postal_code = this.state.businessLocationPostcode;
            businessLocationObj.address_country = this.state.businessLocationCountry;
            businessLocationObj.address_latitude = Number(parseFloat(this.state.businessLocationLat).toFixed(7));
            businessLocationObj.address_longitude = Number(parseFloat(this.state.businessLocationLng).toFixed(7));
            businessLocationObj.contact_email = this.state.businessLocationEmail;
            businessLocationObj.contact_number = contact_number;
            businessLocationObj.contact_number_iso = this.state.businessLocationContactNumberIso;
            this.props.actions.updateBusinessLocation(businessLocationObj);
            this.props.triggerNotification("Business location updated successfully.", "success", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate('/admin/business-locations');
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while updating the business location.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    deleteBusinessLocation = async () => {
        debugger;
        this.setState({ deleteModalLoading: true });
        try {
            await Api.deleteBusinessLocation(this.state.businessLocationId);
            this.props.actions.deleteBusinessLocation(this.state.businessLocationId);
            this.props.triggerNotification("Business location deleted successfully.", "success");
            this.props.navigate('/admin/business-locations');
            return;
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while deleting the business location. Please contact support if this problem persists", "danger");
        }
        this.setState({ deleteModalLoading: false });
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12} sm={11} md={10} lg={9} xl={6} className="ms-auto me-auto">
                        <Card className="card-business card-business-sm" style={this.props.business.business_img ? { backgroundImage: `url("${CDN_URL}/` + this.props.business.business_img + '")' } : null}>
                            {this.props.business.business_img ?
                            <div
                                className="card-overlay"
                                style={this.props.business.hub_cover_image_overlay_gradient_start && this.props.business.hub_cover_image_overlay_gradient_end ? (
                                    { background: `linear-gradient(160deg, ${this.props.business.hub_cover_image_overlay_gradient_start} 0%, ${this.props.business.hub_cover_image_overlay_gradient_end} 100%)` }
                                ) : (
                                    { background: `rgba(0, 0, 0, 0.6)` }
                                )}
                            />
                            : null}
                            <CardBody className="text-center">
                                {this.props.business && this.props.business.business_logo ?
                                    <img className="card-logo" src={`${CDN_URL}/` + this.props.business.business_logo} alt="BusinessLogo"/>
                                :
                                    <CardTitle style={!this.props.business.business_img && !this.props.business.business_img ?  { color: '#000000' } : null}>{this.props.business ? this.props.business.business_name : 'Loading...'}</CardTitle>
                                }
                                <h5 className="card-description" style={this.props.business.hub_cover_image_address ? { color: this.props.business.hub_cover_image_address, marginBottom: 0 } : { marginBottom: 0 }}>
                                    {this.state.businessLocationData ? this.state.businessLocationData.business_location_name : 'Edit Location'}
                                </h5>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Details</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <label>Location name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationName}
                                                onChange={(e) => this.handleChange(e, 'name')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Address</h6>
                            </CardHeader>
                            <CardBody>
                                <CardMap
                                    lat={this.state.businessLocationLat}
                                    lng={this.state.businessLocationLng}
                                    submitAddress={this.handleAddressChange}
                                    submitLatLng={this.handleLatLngChange}
                                />
                                <Row>
                                    <Col md={3}>
                                        <label>Unit Name</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationUnit ? this.state.businessLocationUnit : ''}
                                                onChange={(e) => this.handleChange(e, 'unit')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <label>Street Number</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationStreetNumber ? this.state.businessLocationStreetNumber : ''}
                                                onChange={(e) => this.handleChange(e, 'streetNumber')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>Street Name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationStreetName ? this.state.businessLocationStreetName : ''}
                                                onChange={(e) => this.handleChange(e, 'streetName')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label>City <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationCity ? this.state.businessLocationCity : ''}
                                                onChange={(e) => this.handleChange(e, 'city')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>State/County <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationState ? this.state.businessLocationState : ''}
                                                onChange={(e) => this.handleChange(e, 'state')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label>Postcode <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessLocationPostcode ? this.state.businessLocationPostcode : ''}
                                                onChange={(e) => this.handleChange(e, 'postCode')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>Country <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={country}
                                                value={this.state.businessLocationCountry ? country.find(x => x.label === this.state.businessLocationCountry) : null}
                                                isOptionSelected={(option, selectValue) => option.label === this.state.businessLocationCountry}
                                                onChange={(e) => this.handleChange(e, 'country')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Localisation</h6>
                                <FormText>
                                    You can amend these settings on the <a href="/" onClick={(e) => { e.preventDefault(); this.props.navigate('/admin/business-settings') }}>Business Settings</a> page.
                                </FormText>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <label>Currency <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isDisabled={true}
                                                options={currency}
                                                isOptionSelected={(option, selectValue) => option.id === this.props.business.currency_id}
                                                value={this.props.business.currency_id ? currency.find(x => x.id === this.props.business.currency_id) : null}
                                                onChange={(e) => this.handleChange(e, 'currency')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <label>Language <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={"English"}
                                                style={{ backgroundColor: '#f9f9f9', color: '#000000' }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <label>Timezone <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isDisabled={true}
                                                options={timezone}
                                                value={this.props.business.timezone_id ? timezone.find(x => x.id === this.props.business.timezone_id) : null}
                                                onChange={(e) => this.handleChange(e, 'timezone')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Contact Details</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <label>Email <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                value={this.state.businessLocationEmail}
                                                onChange={(e) => this.handleChange(e, 'email')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <label>Contact Number <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <PhoneInput
                                                className="contact-input"
                                                value={this.state.businessLocationContactNumber}
                                                country={'gb'}
                                                placeholder="Contact Number"
                                                inputClass="form-control"
                                                onChange={(value, country, e, formattedValue) => this.handleChange([value, country, e, formattedValue], 'contactNumber')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitBusinessLocation()}>
                                        Save Changes
                                    </Button>
                                    {this.state.deletable && (
                                        <Button color="danger" onClick={() => this.setState({ deleteModalVisible: true })}>
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {this.state.deleteModalVisible && (
                <ConfirmDeleteModal
                    isVisible={true}
                    setVisible={() => this.setState({ deleteModalVisible: false })}
                    onConfirm={this.deleteBusinessLocation}
                    loading={this.state.deleteModalLoading}
                    dataEntityName={this.state.businessLocationName}
                />
            )}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        userRole: state.userRole
    };
  }
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessLocationEdit));