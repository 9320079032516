import React from 'react';
// Mui components
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import duration from "constants/duration";

const TimePicker = (props) => {
    const { label, defaultValue, value, ...rest } = props;
    return (
        <Autocomplete
            options={duration}
            defaultValue={defaultValue ?? duration?.find((option) => option.label === '1hr')}
            renderInput={(params) => <TextField {...params} label={label} />}
            value={value ? duration.find((option) => option.value === value) : undefined}
            {...rest}
        />
    )
};

export default TimePicker;