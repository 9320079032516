import {
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
    restrictToVerticalAxis,
    restrictToWindowEdges
} from '@dnd-kit/modifiers';

import Sortable from './Sortable';

export default function OrderEditor(props) {

    const { lockAxis, entity, ...rest } = props;

    return (
        <Sortable
            strategy={verticalListSortingStrategy}
            modifiers={lockAxis ? [restrictToVerticalAxis, restrictToWindowEdges] : undefined}
            {...rest}
        />
    );
}