import axios from 'axios';

import { API_URL_PROD, API_URL_DEV } from "constants/urls";

// Axios config
axios.defaults.baseURL = process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_DEV;

const Api = {
    // Auth
    signIn: async (data) => {
        return axios.post('/hub/signin', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    signOut: async () => {
        return axios.post('/hub/signout', {}, { withCredentials: true }).then(response => {
            return response;
        });
    },
    resetPasswordNoAuth: async (data) => {
        return axios.post('/hub/reset_password_no_auth', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getAuthState: async () => {
        return axios.get('/hub/get_auth_state', { withCredentials: true }).then(response => {
            return response;
        });
    },
    // User
    getUser: async () => {
        return axios.get('/hub/get_user', { withCredentials: true }).then(response => {
            return response;
        });
    },
    // MFA
    getMfa: async () => {
        return axios.get('/hub/mfa', { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Calendar settings
    postCalendarSettings: async (data) => {
        return axios.post('/hub/calendar_settings', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Selected business location
    postSelectedBusinessLocation: async (data) => {
        return axios.post('/hub/selected_business_location', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Business
    getBusiness: async () => {
        return axios.get('/hub/get_business', { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Business Locations
    getBusinessLocations: async () => {
        return axios.get('/hub/get_business_locations', { withCredentials: true }).then(response => {
            return response;
        });
    },
    addBusinessLocation: async (data) => {
        return axios.post('/hub/add_business_location', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBusinessLocation: async (data) => {
        return axios.post('/hub/update_business_location', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteBusinessLocation: async (businessLocationId) => {
        return axios.delete(`/hub/business_location/${businessLocationId}`, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Staff
    getStaff: async () => {
        return axios.get('/hub/get_staff', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getStaffHours: async (startDate, endDate, locationId) => {
        return axios.post('/hub/get_staff_hours', { startDate, endDate, locationId }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Booking Count
    getBookingCount: async (startDate, endDate, locationId) => {
        return axios.post('/hub/get_booking_count', { startDate, endDate, locationId }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Gallery
    getGalleryImages: async () => {
        return axios.get('/hub/get_gallery_images', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getGalleryImage: async (data) => {
        return axios.post('/hub/get_gallery_image', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addGalleryImage: async (data) => {
        return axios.post('/hub/add_gallery_image', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateGalleryImage: async (data) => {
        return axios.post('/hub/update_gallery_image', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteGalleryImage: async (data) => {
        return axios.post('/hub/delete_gallery_image', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // To Do
    getToDo: async () => {
        return axios.get('/hub/get_to_do', { withCredentials: true }).then(response => {
            return response;
        });
    },
    addToDo: async (toDoTask) => {
        return axios.post('/hub/add_to_do', { toDoTask }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateToDo: async (toDoId, toDoStatus) => {
        return axios.post('/hub/update_to_do', { toDoId, toDoStatus }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteToDo: async (toDoId) => {
        return axios.post('/hub/delete_to_do', { toDoId }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Calendar
    getCalendarEvents: async (startDate, endDate, businessLocationId) => {
        return axios.post('/hub/get_calendar_events', { startDate, endDate, businessLocationId }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    dragUpdate: async (eventData) => {
        return axios.post('/hub/drag_update', eventData, { withCredentials: true }).then(response => {
            return response;
        });
    },
    resizeUpdate: async (eventData) => {
        return axios.post('/hub/resize_update', eventData, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Account
    updateAccountDetails: async (firstname, lastname) => {
        return axios.post('/hub/update_account', { firstname, lastname }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateAccountImage: async (img, isDefault) => {
        return axios.post('/hub/update_account_image', { img, default: isDefault }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    resetPassword: async () => {
        return axios.post('/hub/reset_password', {}, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Billing
    getInvoices: async () => {
        return axios.get('/hub/get_invoices', { withCredentials: true }).then(response => {
            return response;
        });
    },
    sendInvoice: async (invoice_id) => {
        return axios.post('/hub/send_invoice', { invoice_id }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getSubscription: async () => {
        return axios.get('/hub/get_subscription', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getPaymentMethods: async () => {
        return axios.get('/hub/get_payment_methods', { withCredentials: true }).then(response => {
            return response;
        });
    },
    setDefaultPaymentMethod: async (payment_method_id) => {
        return axios.post('/hub/set_default_payment_method', { payment_method_id }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    createSetupIntent: async () => {
        return axios.get('/hub/create_setup_intent', { withCredentials: true }).then(response => {
            return response;
        });
    },
    deletePaymentMethod: async (payment_method_id) => {
        return axios.post('/hub/delete_payment_method', { payment_method_id }, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Referrals
    getReferrals: async () => {
        return axios.get('/hub/get_referrals', { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Services
    getServiceCategories: async () => {
        return axios.get('/hub/get_service_categories', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getServices: async () => {
        return axios.get('/hub/get_services', { withCredentials: true }).then(response => {
            return response;
        });
    },
    addService: async (service_data) => {
        return axios.post('/hub/add_service', service_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    editService: async (service_data) => {
        return axios.post('/hub/edit_service', service_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteService: async (service_data) => {
        return axios.post('/hub/delete_service', service_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addServiceCategory: async (service_category_data) => {
        return axios.post('/hub/add_service_category', service_category_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    editServiceCategory: async (service_category_data) => {
        return axios.post('/hub/edit_service_category', service_category_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteServiceCategory: async (service_category_data) => {
        return axios.post('/hub/delete_service_category', service_category_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Customers
    getCustomers: async (customer_data) => {
        return axios.post('/hub/get_customers', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    searchCustomers: async (customer_data) => {
        return axios.post('/hub/search_customers', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getCustomer: async (customer_data) => {
        return axios.post('/hub/get_customer', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getCustomerPushTokens: async (customer_data) => {
        return axios.post('/hub/get_customer_push_tokens', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    sendCustomerPushNotification: async (customer_data) => {
        return axios.post('/hub/send_customer_push_notification', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getCustomerBookings: async (customer_data) => {
        return axios.post('/hub/get_customer_bookings', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addCustomer: async (customer_data) => {
        return axios.post('/hub/add_customer', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    editCustomer: async (customer_data) => {
        return axios.post('/hub/edit_customer', customer_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Products
    getProducts: async () => {
        return axios.get('/hub/get_products', { withCredentials: true }).then(response => {
            return response;
        });
    },
    addProduct: async (product_data) => {
        return axios.post('/hub/add_product', product_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateProduct: async (product_data) => {
        return axios.post('/hub/update_product', product_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteProduct: async (product_data) => {
        return axios.post('/hub/delete_product', product_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Break
    getBreak: async (break_data) => {
        return axios.post('/hub/get_break', break_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addBreak: async (break_data) => {
        return axios.post('/hub/add_break', break_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBreak: async (break_data) => {
        return axios.post('/hub/update_break', break_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteBreak: async (break_data) => {
        return axios.post('/hub/delete_break', break_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Booking
    searchBookings: async (data) => {
        return axios.post('/hub/search_bookings', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBooking: async (booking_data) => {
        return axios.post('/hub/get_booking', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBookingPayments: async (booking_data) => {
        return axios.post('/hub/get_booking_payments', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    refundBookingPayment: async (booking_data) => {
        return axios.post('/hub/refund_booking_payment', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addBooking: async (booking_data) => {
        return axios.post('/hub/add_booking', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBooking: async (booking_data) => {
        return axios.post('/hub/update_booking', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBookingStatus: async (booking_data) => {
        return axios.post('/hub/update_booking_status', booking_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Staff
    addStaff: async (staff_data) => {
        return axios.post('/hub/add_staff', staff_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateStaff: async (staff_data) => {
        return axios.post('/hub/update_staff', staff_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteStaff: async (staff_data) => {
        return axios.post('/hub/delete_staff', staff_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Staff Hours
    updateStaffHours: async (staff_data) => {
        return axios.post('/hub/update_staff_hours', staff_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteStaffHours: async (staff_data) => {
        return axios.post('/hub/delete_staff_hours', staff_data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBusinessDate: async (data) => {
        return axios.post('/hub/update_business_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Staff Schedules
    getStaffSchedules: async (data) => {
        return axios.post('/hub/get_staff_schedules', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getStaffScheduleByDate: async (data) => {
        return axios.post('/hub/get_staff_schedule_by_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getStaffScheduleById: async (data) => {
        return axios.post('/hub/get_staff_schedule_by_id', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addStaffSchedule: async (data) => {
        return axios.post('/hub/add_staff_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateStaffSchedule: async (data) => {
        return axios.post('/hub/update_staff_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteStaffSchedule: async (data) => {
        return axios.post('/hub/delete_staff_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    // Settings
    getBookingPreferences: async () => {
        return axios.post('/hub/get_booking_preferences', {}, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBookingPreferences: async (data) => {
        return axios.post('/hub/update_booking_preferences', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBookingTerms: async () => {
        return axios.post('/hub/get_booking_terms', {}, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBookingTerms: async (data) => {
        return axios.post('/hub/update_booking_terms', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateRevenueProtectionOptions: async (data) => {
        return axios.post('/hub/update_revenue_protection_options', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateHubAppearance: async (data) => {
        return axios.post('/hub/update_hub_appearance', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateNotificationPreferences: async (data) => {
        return axios.post('/hub/update_notification_preferences', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getPushNotifications: async () => {
        return axios.get('/hub/get_push_notifications', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getPushNotificationInformation: async () => {
        return axios.get('/hub/get_push_notification_information', { withCredentials: true }).then(response => {
            return response;
        });
    },
    sendPushNotification: async (data) => {
        return axios.post('/hub/send_push_notification', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getAppPreferences: async () => {
        return axios.get('/hub/get_app_preferences', { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateAppPreferences: async (data) => {
        return axios.post('/hub/update_app_preferences', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getAppStatus: async () => {
        return axios.get('/hub/get_app_status', { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBusinessSettings: async (data) => {
        return axios.post('/hub/update_business_settings', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBusinessSchedules: async () => {
        return axios.get('/hub/get_business_schedules', { withCredentials: true }).then(response => {
            return response;
        });
    },
    addBusinessSchedule: async (data) => {
        return axios.post('/hub/add_business_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBusinessSchedule: async (data) => {
        return axios.post('/hub/update_business_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteBusinessSchedule: async (data) => {
        return axios.post('/hub/delete_business_schedule', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBusinessClosedDates: async (data) => {
        return axios.post('/hub/get_business_closed_dates', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getBusinessClosedDate: async (data) => {
        return axios.post('/hub/get_business_closed_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    addBusinessClosedDate: async (data) => {
        return axios.post('/hub/add_business_closed_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    updateBusinessClosedDate: async (data) => {
        return axios.post('/hub/update_business_closed_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    deleteBusinessClosedDate: async (data) => {
        return axios.post('/hub/delete_business_closed_date', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    connectInstagram: async (data) => {
        return axios.post('/hub/connect_instagram', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    disconnectInstagram: async (data) => {
        return axios.post('/hub/disconnect_instagram', data, { withCredentials: true }).then(response => {
            return response;
        });
    },
    getInstagramData: async () => {
        return axios.get('/hub/get_instagram_data', { withCredentials: true }).then(response => {
            return response;
        });
    },
    getWaitList: async (dateStr) => {
        return axios.get(`/hub/wait_list?date=${dateStr}`, { withCredentials: true }).then(response => {
            return response;
        });
    },
    postWaitList: async (data) => {
        return axios.post(`/hub/wait_list`, data, { withCredentials: true });
    },
    deleteWaitList: async (userWaitListId) => {
        return axios.delete(`/hub/wait_list/${userWaitListId}`, { withCredentials: true });
    },
    getEmailPreferences: async () => {
        return axios.get(`/hub/email_preferences`, { withCredentials: true });
    },
    postEmailPreferences: async (data) => {
        return axios.post(`/hub/email_preferences`, data, { withCredentials: true });
    },
    // Permissions
    getPermissions: async() => {
        return axios.get(`/hub/permissions`, { withCredentials: true });
    },
    postPermissions: async(data) => {
        return axios.post(`/hub/permissions`, data, { withCredentials: true });
    },
    // Accounts
    getBusinessUsers: async() => {
        return axios.get(`/hub/business_user`, { withCredentials: true });
    },
    postBusinessUser: async (data) => {
        return axios.post(`/hub/business_user`, data, { withCredentials: true });
    },
    putBusinessUser: async (data) => {
        return axios.put(`/hub/business_user`, data, { withCredentials: true });
    },
    // User merge
    getUserMerge: async(page, count) => {
        return axios.get(`/hub/user_merge`, { params: { page, count }, withCredentials: true });
    },
    postUserMerge: async(data) => {
        return axios.post(`/hub/user_merge`, data, { withCredentials: true });
    },
    // Stripe
    getStripeAccount: async() => {
        return axios.get(`/hub/stripe_account`, { withCredentials: true });
    },
    postStripeAccount: async() => {
        return axios.post(`/hub/stripe_account`, {}, { withCredentials: true });
    },
    // Courses
    getCoursesData: async() => {
        return axios.get(`/hub/course`, { withCredentials: true });
    },
    getCourseData: async(courseId) => {
        return axios.get(`/hub/course/${courseId}`, { withCredentials: true });
    },
    postCourseData: async(courseName) => {
        return axios.post(`/hub/course`, { business_course_name: courseName }, { withCredentials: true });
    },
    putCourseData: async(courseData) => {
        return axios.put(`/hub/course`, courseData, { withCredentials: true });
    },
    deleteCourseData: async(courseId) => {
        return axios.delete(`/hub/course/${courseId}`, { withCredentials: true });
    },
    // Plan
    patchPlan: async(body) => {
        return axios.patch(`/hub/plan`, body, { withCredentials: true });
    },
};

export default Api;