import React from 'react';
// Custom components
import ImagePlaceholder from 'components/Image/ImagePlaceholder';

function CourseImage(props) {
    const { imgSrc } = props;
    if(!imgSrc) return <ImagePlaceholder height={110} width={140} iconSize={40} />;
    return (
        <img
            src={imgSrc}
            alt="Course thumbnail"
            style={{ maxWidth: 140, height: 110, objectFit: 'cover' }}
        />
    );
}

export default CourseImage;