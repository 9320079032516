import React from "react";
// MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// Custom components
import ImageDropZone from "components/ImageCropper/ImageDropZone";
import ColourInput from "components/ColourPicker/ColourInput";

function Settings(props) {
    
    const {
        title,
        updateTitle,
        description,
        updateDescription,
        image,
        updateImage,
        colour,
        updateColour,
        published,
        updatePublished,
        editable
    } = props;

    return (
        <Stack spacing={3} sx={{ pt: 4 }}>
            <TextField
                id="title"
                value={title}
                label="Course Title"
                variant="outlined"
                onChange={e => updateTitle(e.target.value)}
                disabled={!editable}
            />
            <TextField
                id="description"
                label="Course Description"
                multiline
                minRows={3}
                maxRows={10}
                value={description}
                onChange={e => updateDescription(e.target.value)}
                disabled={!editable}
            />
            <Box>
                <Typography variant="subtitle2">Course Image</Typography>
                <ImageDropZone imageData={image} submitImageData={updateImage} disabled={!editable} />
            </Box>
            <Box>
                <Typography variant="subtitle2">Course Colour</Typography>
                <Typography variant="caption">This colour will be used for branding on your widget, app, and an identifying colour on your calendar.</Typography>
                <Box sx={{ mt: 1 }}>
                    <ColourInput colour={colour} submitColour={updateColour} disabled={!editable} />
                </Box>
            </Box>
            <Box>
                <Typography variant="subtitle2">Published</Typography>
                <Typography variant="caption">Once published, a course will:</Typography>
                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                    <ListItem sx={{ display: 'list-item', py: 0 }}><Typography variant="caption">appear on your staff members' calendar</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item', py: 0 }}><Typography variant="caption">be bookable by customers if booking access is set to "Open to all customers"</Typography></ListItem>
                </List>
                <Switch
                    color="success"
                    checked={published}
                    onChange={(e) => updatePublished(e.target.checked)}
                    disabled={!editable}
                />
            </Box>
        </Stack>
    );
}

export default Settings;