import React, { useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Generic components
import Modal from 'components/Material/Modal';
import CourseAttendeeForm from './CourseAttendeeForm';

function CourseAttendeeFormModal(props) {
    const { isVisible, setVisible, ...rest } = props;
    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">Attendee Settings</Typography>
                <Box sx={{ mt: 4 }}>
                    <CourseAttendeeForm {...rest} />
                </Box>
            </Box>
        </Modal>
    );
}

export default CourseAttendeeFormModal;