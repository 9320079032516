import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

function StaffPermissionsGrid(props) {

    const { entity, permissions, loading } = props;

    function getPermissionCheckbox(key = undefined, userRoleId = undefined, forceChecked = false) {
        let checked = forceChecked;
        if(!forceChecked && permissions && userRoleId) {
            const permissionsObj = permissions.find(x => x.business_user_role_type_id === userRoleId);
            if(permissionsObj && (entity in permissionsObj)) {
                checked = permissionsObj[entity][key];
            }
        }
        return (
            <Checkbox
                disabled={forceChecked || loading}
                checked={checked}
                onChange={(e, checked) => props.onCheckboxClick(userRoleId, entity, key, checked)}
            />
        );
    }

    function createData(label, key, allChecked=false) {
        return {
            label,
            admin: getPermissionCheckbox(undefined , undefined, true),
            high: getPermissionCheckbox(key, 2, allChecked),
            medium: getPermissionCheckbox(key, 3, allChecked),
            low: getPermissionCheckbox(key, 4, allChecked),
            basic: getPermissionCheckbox(key, 5, allChecked)
        };
    }

    const cellStyle = { borderRight: '1px solid rgba(224, 224, 224, 1)' };
    const headerCellStyle = { textTransform: 'uppercase', fontWeight: '600', borderBottomWidth: 2, ...cellStyle };

    let rows = [];
    let entityTitle;
    // Titles
    switch(entity) {
        case "location":
            entityTitle = "Locations";
            break;
        case "booking":
            entityTitle = "Bookings";
            break;
        case "break":
            entityTitle = "Breaks";
            break;
        case "service":
            entityTitle = "Services";
            break;
        case "hours":
            entityTitle = "Staff hours";
            break;
        case "product":
            entityTitle = "Products";
            break;
        case "customer":
            entityTitle = "Customers";
            break;
        case "report":
            entityTitle = "Reports";
            break;
        case "date":
            entityTitle = "Business dates";
            break;
        case "setting":
            entityTitle = "Business settings";
            break;
        case "notification":
            entityTitle = "Push notifications";
            break;
        case "waitlist":
            entityTitle = "Wait list";
            break;
        case "course":
            entityTitle = "Classes & Courses";
        default:
            entityTitle = entity;
    }
    // Grids
    switch(entity) {
        case  "location":
            rows = [
                createData('View own working locations', undefined, true),
                createData('View all locations', 'read_all'),
                createData('Add/Edit', 'update'),
                createData('Delete', 'delete')
            ];
            break;
        case "booking":
            rows = [
                createData('View own bookings', undefined, true),
                createData('View all bookings', 'read_all'),
                createData('Create', 'create'),
                createData('Update', 'update'),
                createData('Cancel', 'cancel')
            ];
            break;
        case "break":
            rows = [
                createData('View own breaks', undefined, true),
                createData('View all breaks', 'read_all'),
                createData('Create', 'create'),
                createData('Update', 'update'),
                createData('Delete', 'delete')
            ];
            break;
        case "hours":
            rows = [
                createData('View own hours', undefined, true),
                createData('View all staff hours', 'read_all'),
                createData('Update own hours', 'update'),
                createData('Update all staff hours', 'update_all')
            ];
            break;
        case "billing":
            rows = [
                createData('View billing details and invoices', 'read'),
                createData('Update billing details', 'update')
            ];
            break;
        case "report":
            rows = [
                createData('View business reports', 'read_all')
            ];
            break;
        case "date":
            rows = [
                createData('Update open/closed dates', 'update')
            ];
            break;
        case "setting":
            rows = [
                createData('Update business settings', 'update')
            ];
            break;
        case "notification":
            rows = [
                createData('View sent notifications', 'read'),
                createData('Send notifications', 'send')
            ];
            break;
        case "permissions":
            rows = [
                createData('Update permissions', 'read'),
            ];
            break;
        case "waitlist":
            rows = [
                createData('View wait list', 'read'),
                createData('Update wait list', 'update'),
                createData('Delete from wait list', 'delete')
            ];
            break;
        case "course":
            rows = [
                createData('View courses', 'read'),
                createData('Update courses', 'update'),
                createData('Delete courses', 'delete')
            ];
            break;
        default:
            rows = [
                createData('View all', undefined, true),
                createData('Create', 'create'),
                createData('Update', 'update'),
                createData('Delete', 'delete'),
            ];
    }

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                <TableRow>
                    <TableCell sx={{...headerCellStyle, fontWeight: '700', fontSize: 15 }}>{entityTitle}</TableCell>
                    <TableCell align="center" sx={headerCellStyle}>Basic</TableCell>
                    <TableCell align="center" sx={headerCellStyle}>Low</TableCell>
                    <TableCell align="center" sx={headerCellStyle}>Medium</TableCell>
                    <TableCell align="center" sx={headerCellStyle}>High</TableCell>
                    <TableCell align="center" sx={headerCellStyle}>Admin</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                        key={row.label}
                        sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                        hover
                    >
                        <TableCell component="th" scope="row" sx={cellStyle}>
                            {row.label}
                        </TableCell>
                        <TableCell align="center" sx={cellStyle} width={'10%'}>{row.basic}</TableCell>
                        <TableCell align="center" sx={cellStyle} width={'10%'}>{row.low}</TableCell>
                        <TableCell align="center" sx={cellStyle} width={'10%'}>{row.medium}</TableCell>
                        <TableCell align="center" sx={cellStyle} width={'10%'}>{row.high}</TableCell>
                        <TableCell align="center" sx={cellStyle} width={'10%'}>{row.admin}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default StaffPermissionsGrid;