import React from 'react';
import adminRoutes from 'routes/adminRoutes';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../actions/index';

import AccessDenied from 'components/Generic/AccessDenied';

const checkAccessPermission = (WrappedComponent) => {
    
    class AccessControl extends React.Component {

        checkAccountAccess() {
            // Find route
            const [layout, ...restPath] = window.location.pathname.split("/").splice(1);
            const path = restPath.join("/");
            const routeObj = adminRoutes.find(x => x.layout === layout && x.path === path);
            if(!routeObj) {
                return false;
            }
            // Check business account access
            if(routeObj.businessAccountAccess && !routeObj.businessAccountAccess.includes(this.props.business.business_account_type_id)) {
                return false;
            }
            // Check account feature access
            if(routeObj.requiredFeatures && !routeObj.requiredFeatures.every(feature => this.props.business.features.includes(feature))) {
                return false;
            }
            // Check business user access
            return this.checkBusinessUserAccess(routeObj);
        }

        checkBusinessUserAccess(routeObj) {
            if(routeObj.accessPermission?.length > 0) {
                for(let accessRole of routeObj.accessPermission) {
                    const [groupKey, ...restKey] = accessRole.split("_");
                    if(this.props.userRole[groupKey][restKey.join("_")] === false) {
                        return false;
                    }
                }
            }
            // Default access to true if no access permissions are specified
            return true;
        }

        render() {
            return (
                <div>
                    {this.checkAccountAccess() ? <WrappedComponent {...this.props}/> : <AccessDenied/>}
                </div>
            )
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            business: state.business,
            businessLocation: state.businessLocation,
            service: state.service,
            serviceDetail: state.serviceDetail,
            staff: state.staff,
            user: state.user,
            userRole: state.userRole
        }
    }
    
    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(serviceActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(AccessControl)
}

export default checkAccessPermission;