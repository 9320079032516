import React, { useState, useEffect } from "react";
// MUI components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
// Sweetalert
import Swal from 'sweetalert2';
// Icons
import { ArrowsOutSimple, ArrowsInSimple } from "@phosphor-icons/react";

// Course components
import CourseModuleList from "../CourseModuleList";
import CourseModuleForm from "../CourseModuleForm";
import CourseClassForm from "../CourseClassForm";
import CourseOrderModal from "../CourseOrderModal";

function Overview(props) {

    const theme = useTheme();

    const { moduleData, setModuleData, businessLocationData, staffData, editable, ...rest } = props;

    const [moduleFormState, setModuleFormState] = useState({ isVisible: false });
    const [classFormState, setClassFormState] = useState({ isVisible: false });
    const [orderModalState, setOrderModalState] = useState({ isVisible: false });

    const [expandedModules, setExpandedModules] = useState([]);

    useEffect(() => {
        if(moduleData?.length > 0) {
            setExpandedModules([moduleData[0].business_course_module_id]);
        }
    }, [moduleData]);

    const handleExpandAll = () => {
        if (expandedModules.length === moduleData.length) {
            setExpandedModules([]);
        } else {
            setExpandedModules(moduleData.map((moduleObj, _) => moduleObj.business_course_module_id));
        }
    }

    const toggleExpanded = (moduleId) => {
        if (expandedModules.includes(moduleId)) {
            setExpandedModules(expandedModules.filter((id) => id !== moduleId));
        } else {
            setExpandedModules([...expandedModules, moduleId]);
        }
    }

    const CollapseButton = () => {
        const canCollapse = expandedModules?.length === moduleData?.length;
        const CollapseButtonIcon = canCollapse ? ArrowsInSimple : ArrowsOutSimple;
        return (
            <Button variant="text" color="primary" size="small" onClick={handleExpandAll}>
                <CollapseButtonIcon size={12} weight="bold" style={{ marginRight: 5 }} />
                {canCollapse ? 'Collapse' : 'Expand'} all
            </Button>
        );
    }

    const MenuButton = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const handleMenuClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleMenuClose = (e) => {
            setAnchorEl(null);
            switch(e.target.id) {
                case 'module-menu-item':
                    setModuleFormState({ isVisible: true });
                    break;
                case 'class-menu-item':
                    setClassFormState({ isVisible: true });
                    break;
                default:
                    break;
            }
        };
        return (
            <React.Fragment>
                <Button
                        variant="outlined"
                        size="small"
                        onClick={handleMenuClick}
                    >
                        Options
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem id="module-menu-item" onClick={handleMenuClose}>Add Module</MenuItem>
                    <MenuItem id="class-menu-item" onClick={handleMenuClose}>Add Class</MenuItem>
                    {moduleData?.length > 1 && <MenuItem id="order-menu-item" onClick={() => setOrderModalState({ isVisible: true })}>Set Module Order</MenuItem>}
                </Menu>
            </React.Fragment>
        );
    }

    const renderModuleForm = () => {
        return (
            <CourseModuleForm
                submitModule={(e) => { props.submitModule(e); setModuleFormState({ isVisible: false }); }}
                setVisible={() => setModuleFormState({ isVisible: false })}
                {...moduleFormState}
            />
        );
    }

    const renderClassForm = () => {
        return (
            <CourseClassForm
                staffData={staffData}
                businessLocationData={businessLocationData}
                moduleData={moduleData}
                submitClass={(e) => { props.submitClass(e); setClassFormState({ isVisible: false }); }}
                setVisible={() => setClassFormState({ isVisible: false })}
                editable={editable}
                {...classFormState}
            />
        );
    }

    const renderOrderModal = () => {
        return (
            <CourseOrderModal
                data={moduleData}
                isVisible={orderModalState.isVisible}
                setVisible={() => setOrderModalState({ isVisible: false })}
                submitData={(e) => { setModuleData(e); setOrderModalState({ isVisible: false }); }}
            />
        )
    }

    const deleteModule = async (moduleId) => {
        const moduleObj = moduleData.find((x) => x.business_course_module_id === moduleId);
        // Check for existence of non-deleted classes
        const activeClasses = moduleObj.classData?.find((x) => x.state !== 3);
        if(activeClasses) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You cannot delete a module with active classes. Please delete the classes first or move them to another module.',
                confirmButtonColor: theme.palette.primary.main
            });
            return;
        }
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: `You are about to delete the module "${moduleObj.business_course_module_name}". This action cannot be undone.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.secondary.main,
            cancelButtonColor: theme.palette.primary.main,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if(!isConfirmed) return;
        // Check if module is new - if so, delete
        if(moduleObj.state === 1) {
            setModuleData(moduleData.filter((x) => x.business_course_module_id !== moduleId));
            return;
        }
        // Set module to deleted state
        const moduleIdx = moduleData.findIndex((x) => x.business_course_module_id === moduleId);
        moduleData[moduleIdx].state = 3;
        setModuleData(moduleData);
    }

    const handleUpdateClass = (moduleId, classId) => {
        const moduleIdx = moduleData.findIndex(x => x.business_course_module_id === moduleId);
        const classData = moduleData[moduleIdx].classData.find(x => x.business_class_id === classId);
        if(!classData) return;
        setClassFormState({ isVisible: true, classData });
    }

    return (
        <React.Fragment>
            {moduleData?.filter(x => x.state !== 3).length > 0 && (
                <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{moduleData ? moduleData.length : 0} modules</Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CollapseButton/>
                        {editable && <MenuButton/>}
                    </Box>
                </Box>
            )}
            <CourseModuleList
                moduleData={moduleData.filter(x => x.state !== 3)}
                expandedModules={expandedModules}
                toggleExpanded={toggleExpanded}
                addClass={(moduleId) => setClassFormState({ isVisible: true, moduleId })}
                addModule={() => setModuleFormState({ isVisible: true })}
                updateModule={(moduleId) => setModuleFormState({ isVisible: true, moduleObj: moduleData.find(x => x.business_course_module_id === moduleId) })}
                deleteModule={deleteModule}
                updateClass={handleUpdateClass}
                editable={editable}
                {...rest}
            />
            {moduleFormState?.isVisible && renderModuleForm()}
            {classFormState?.isVisible && renderClassForm()}
            {orderModalState?.isVisible && renderOrderModal()}
        </React.Fragment>
    );
}

export default Overview;