import React, { useState } from 'react';
// Mui components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDatePicker = (props) => {
    const [calendarVisible, setCalendarVisible] = useState(false);
    return (
        <DatePicker
            {...props}
            open={calendarVisible}
            onOpen={() => setCalendarVisible(true)}
            onClose={() => setCalendarVisible(false)}
            format="dddd, Do MMM YYYY"
            slotProps={{ textField: { onClick: () => setCalendarVisible(true), focused: calendarVisible } }}
        />
    )
};

export default CustomDatePicker;