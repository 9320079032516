import React from 'react';
// Material components
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
// Icons
import { User, Phone, Envelope } from '@phosphor-icons/react';
// Custom components
import UserAvatar from 'components/Material/UserAvatar';
// Constants
import { CDN_URL } from 'constants/urls';

export default function CustomerDisplay(props) {
    const { name, email, phone, img } = props;
    
    const renderCustomerContactDetail = (contactDetail, contactType, size="md") => {
        // Size
        let iconSize = 18;
        let textVariant = "body2";
        if(size === "sm") {
            iconSize = 16;
            textVariant = "caption";
        }
        // Icon
        let contactIcon;
        switch(contactType) {
            case "email":
                contactIcon = <Envelope size={iconSize}/>;
                break;
            case "phone":
                contactIcon = <Phone size={iconSize}/>;
                break;
            default:
                contactIcon = <User size={iconSize}/>;
        }
        return (
            <Box display="flex" gap={1} alignItems="center">
                {contactIcon}
                <Typography variant={textVariant}>{contactDetail ? contactDetail : '----------------'}</Typography>
            </Box>
        );
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <UserAvatar name={name} imgPath={img ? `${CDN_URL}/${img}` : null} />
            <div>
                <Typography variant="subtitle1">{name}</Typography>
                {renderCustomerContactDetail(email, "email", "sm")}
                {renderCustomerContactDetail(phone, "phone", "sm")}
            </div>
        </Stack>
    );
}