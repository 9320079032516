import React, { useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// Generic components
import Modal from 'components/Material/Modal';
import OrderEditor from 'components/OrderEditor/OrderEditor';

function CourseModuleOrderModal(props) {
    const { isVisible, setVisible, data, submitData } = props;

    const [items, setItems] = useState(data.map((module, index) => {
        return {
            id: module.business_course_module_id,
            value: module.business_course_module_name
        };
    }));

    const onSubmit = () => {
        submitData( 
            items.map((itemObj, index) => {
                const moduleObj = data.find(module => module.business_course_module_id === itemObj.id);
                const moduleState = moduleObj.state === 0 ? 2 : moduleObj.state;
                return { ...moduleObj, display_order: index, state: moduleState };
            })
        );
    }
    
    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="xs"
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Module Order</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Set
                    </Button>
                </Stack>
                <Box sx={{ maxHeight: 500, mt: 3 }}>
                    <OrderEditor
                        lockAxis
                        items={items}
                        onReorder={x => setItems(x)}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default CourseModuleOrderModal;