import React, { useState, useEffect } from "react";
// MUI components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';

function CourseAttendeeForm(props) {
    
    const {
        onSubmit,
        attendeeCapacity: defaultAttendeeCapacity,
        inviteOnly: defaultInviteOnly,
        editable
    } = props;

    const [attendeeCount, setAttendeeCount] = useState('no_limit');
    const [attendeeCountCustom, setAttendeeCountCustom] = useState(null);
    const [formError, setFormError] = useState(null);
    const [inviteOnly, setInviteOnly] = useState(false);

    useEffect(() => {
        if(!defaultAttendeeCapacity) {
            setAttendeeCount('no_limit');
        } else if(defaultAttendeeCapacity > 20) {
            setAttendeeCount('custom');
            setAttendeeCountCustom(defaultAttendeeCapacity);
        } else {
            setAttendeeCount(defaultAttendeeCapacity);
        }
        setInviteOnly(defaultInviteOnly);
    }, [defaultAttendeeCapacity, defaultInviteOnly]);

    const handleSubmit = () => {
        let attendeeCapacity = null;
        switch(attendeeCount) {
            case 'no_limit':
                attendeeCapacity = null;
                break;
            case 'custom':
                if(attendeeCountCustom === null || !Number.isInteger(attendeeCountCustom)) {
                    setFormError(['attendee_count_custom']);
                    return;
                }
                attendeeCapacity = attendeeCountCustom;
                break;
            default:
                attendeeCapacity = attendeeCount
        }
        setFormError(null);
        onSubmit({ attendeeCapacity, inviteOnly });
    }

    const attendeeFieldGridSize = attendeeCount === "custom" ? 6 : 12;
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={attendeeFieldGridSize} md={attendeeFieldGridSize}>
                    <FormControl fullWidth>
                        <InputLabel id="attendee_count_label">How many can attend?</InputLabel>
                        <Select
                            labelId="attendee_count_label"
                            id="attendee_count"
                            label="How many can attend?"
                            value={attendeeCount}
                            onChange={(e) => setAttendeeCount(e.target.value)}
                            disabled={!editable}
                        >
                            <MenuItem value={'no_limit'}>No limit</MenuItem>
                            {Array.from({ length: 20 }, (_, i) => (
                                <MenuItem key={'attendee_count_' + (i + 1)} value={i + 1}>{i + 1}</MenuItem>
                            ))}
                            <MenuItem value={'custom'}>Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {attendeeCount === "custom" && (
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="attendee_count_custom"
                            label="Number of attendees"
                            type="number"
                            value={attendeeCountCustom}
                            onChange={(e) => setAttendeeCountCustom(parseInt(e.target.value))}
                            fullWidth
                            inputProps={{ min: 1 }}
                            error={formError && formError.includes('attendee_count_custom')}
                            disabled={!editable}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                        <InputLabel id="booking-access-label">Booking access</InputLabel>
                        <Select
                            labelId="booking-access-label"
                            id="booking_access"
                            label="Booking access"
                            value={inviteOnly}
                            onChange={(e) => setInviteOnly(e.target.value)}
                            disabled={!editable}
                        >
                            <MenuItem value={false}>Open to all customers</MenuItem>
                            <MenuItem value={true}>Invite only</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {editable && (
                    <Grid item xs={12} sm={12} md={12}>
                        <Button variant="contained" onClick={handleSubmit}>Set</Button>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default CourseAttendeeForm;