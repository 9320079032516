import React from 'react';
// Mui components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { MapPin } from '@phosphor-icons/react';
import Typography from '@mui/material/Typography';

import formattingUtils from 'utilities/formatting';

const BusinessLocationSelect = (props) => {
    const { businessLocationData, value, ...rest } = props;
    const renderBusinessLocationAddress = (businessLocationObj) => {
        const businessLocationAddress = formattingUtils.getFormattedBusinessLocationAddress(businessLocationObj);
        return businessLocationAddress?.length > 50 ? `${businessLocationAddress.slice(0, 70)}...` : businessLocationAddress;
    }
    return (
        <Select
            {...rest}
            defaultValue={businessLocationData?.length > 0 && businessLocationData[0].business_location_id}
            value={value ? value : undefined}
            inputProps={{ sx: { padding: '12px 14px' } }}
        >
            {businessLocationData.map((businessLocationObj,) => (
                <MenuItem
                    key={`business-location-${businessLocationObj.business_location_id}-menu-item`}
                    value={businessLocationObj.business_location_id}
                >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <MapPin size={20} />
                        <Stack direction="column" gap={0.5}>
                            <Typography variant="body1" sx={{ lineHeight: 1 }}>
                                {businessLocationObj.business_location_name}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ lineHeight: 1, fontSize: '0.7rem' }} noWrap>
                                {renderBusinessLocationAddress(businessLocationObj)}
                            </Typography>
                        </Stack>
                    </Stack>
                </MenuItem>
            ))}
        </Select>
    )
};

export default BusinessLocationSelect;