import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Components
import Grid from '@mui/material/Grid';
// Custom Components
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import CoursesLanding from "../../components/Courses/CoursesLanding";
import CourseForm from "../../components/Courses/CourseForm";
import CoursesCard from "../../components/Courses/CoursesCard";
// API
import Api from 'api/index';
// Constants
import accountFeatures from 'constants/accountFeatures';

function Courses(props) {

    const navigate = useNavigate();

    const [landingEnabled, setLandingEnabled] = useState(false);
    const [isAddModalVisible, setAddModalVisible] = React.useState(false);
    const [courseData, setCourseData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);

    const loadCourseData = async () => {
        setLoading(true);
        try {
            const { data } = await Api.getCoursesData();
            setCourseData(data.courseData);
        } catch (error) {
            console.error(error);
            props.triggerNotification("An error occured while retrieving course data. If this problem persists, please contact Styler support at support@styler.digital.", "danger");
        }
        setLoading(false);
    };

    useEffect(() => {
        const isLandingEnabled = !props.business.features.includes(accountFeatures.COURSES);
        setLandingEnabled(isLandingEnabled);
        if(!isLandingEnabled) {
            loadCourseData();
        }
    }, []);

    const handleCoursesEnabled = () => {
        const businessDataCopy = { ...props.business };
        businessDataCopy.features.push(accountFeatures.COURSES);
        props.actions.loadBusiness(businessDataCopy);
        setLandingEnabled(false);
    }

    const renderLanding = () => {
        return (
            <div className="page-container">
                <div className="page-column">
                    <CoursesLanding
                        userRole={props.userRole}
                        onFeatureAdded={handleCoursesEnabled}
                        triggerNotification={props.triggerNotification}
                    />
                </div>
            </div>
        );
    };

    const renderCoursesCard = () => {
        const filteredCourseData = courseData.filter(course => 
            course?.business_course_name?.toLowerCase().includes(searchValue.toLowerCase())
        );
        return (
            <React.Fragment>
                <PanelHeader size="sm"/>
                <div className="content">
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12} sm={12} md={11} lg={8} xl={6}>
                            <CoursesCard
                                courseData={filteredCourseData}
                                loading={loading}
                                navigate={navigate}
                                onAddClick={() => setAddModalVisible(true)}
                                onSearchChange={(val) => setSearchValue(val)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {landingEnabled ? renderLanding() : renderCoursesCard()}
            <CourseForm
                isVisible={isAddModalVisible}
                setVisible={() => setAddModalVisible(!isAddModalVisible)}
                submit={(courseId) => navigate(`/admin/course?id=${courseId}`)}
            />
        </React.Fragment>
    );
}

export default Courses;