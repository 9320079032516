import React, { useState } from "react";
import { debounce } from "lodash";
// MUI components
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
// Custom components
import CustomerDisplay from "./CustomerDisplay";
// API
import Api from 'api/index';

export default function CustomerSearch(props) {

    const { onSelect, label } = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };

    const handleSearch = async (query) => {
        if(!query || query.length < 3) return;
        setLoading(true);
        try {
            const { data } = await Api.searchCustomers({ search_string: query });
            setOptions(data.customerData);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const handleSearchDelayed = debounce(handleSearch, 200);

    return (
        <Autocomplete
            blurOnSelect
            clearOnBlur
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            options={options}
            loading={loading}
            onInputChange={(e, v, r) => r === "input" && handleSearchDelayed(v)}
            filterOptions={(x) => x}
            renderOption={(props, option) => (
                <li {...props}>
                    <CustomerDisplay
                        name={`${option.user_firstname} ${option.user_lastname}`}
                        email={option.user_email}
                        phone={option.user_phone}
                    />
                </li>
            )}
            getOptionLabel={(option) => `${option.user_firstname} ${option.user_lastname}`}
            onChange={(e, v, r) => r === "selectOption" && onSelect(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || "Search for a customer..."}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    )
}