/*eslint-disable*/
import React from "react";
//import { Container } from "reactstrap";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container maxWidth={false}>
          <Stack flex justifyContent="space-between" flexDirection="row" sx={window.innerWidth < 600 ? { display: 'block' } : undefined}>
            <div>
              {this.props.default && window.innerWidth >= 600 && (
                <ButtonGroup variant="text">
                  <Button target="_blank" href="https://help.styler.digital/legal/privacy_policy" rel="noreferrer" sx={{ fontWeight: '500', textTransform: 'none' }}>Privacy Policy</Button>
                  <Button target="_blank" href="https://help.styler.digital" rel="noreferrer" sx={{ fontWeight: '500', textTransform: 'none' }}>Help Centre</Button>
                </ButtonGroup>
              )}
            </div>
            <div className="copyright">
              V{process.env.REACT_APP_VERSION} &copy; {1900 + new Date().getYear()} Styler Digital
            </div>
          </Stack>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
