import React from 'react';
// Mui components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import UserAvatar from './UserAvatar';
import { fontSize } from '@mui/system';

const StaffSelect = (props) => {
    const { staffData, value, ...rest } = props;
    return (
        <Select
            value={value}
            multiple
            renderValue={(selected) => {
                const selectedStaff = staffData.filter((staffObj) => selected.includes(staffObj.id)).map((y) => `${y.firstname} ${y.lastname}`);
                return selectedStaff.join(', ');
            }}
            {...rest}
        >
            {staffData.map((staffObj,) => {
                const staffName = `${staffObj.firstname} ${staffObj.lastname}`;
                return (
                    <MenuItem
                        key={staffObj.id}
                        value={staffObj.id}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Checkbox checked={value.includes(staffObj.id)} />
                            <UserAvatar
                                name={staffName}
                                imgPath={`https://cdn.whatstyle.com/${staffObj.staff_img}`}
                                sx={{ width: 32, height: 32, fontSize: '1rem' }}
                            />
                            <ListItemText primary={staffName} />
                        </Stack>
                    </MenuItem>
                );
            })}
        </Select>
    )
};

export default StaffSelect;