import React, { useState } from 'react';
// MUI components
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import { ChalkboardTeacher } from '@phosphor-icons/react';
// Custom components
import Modal from 'components/Material/Modal';
// Constants
import accountFeatures from 'constants/accountFeatures';
// API
import Api from 'api/index';

function EditPlanModal(props) {
    const { accountFeature, onFeatureAdded, triggerNotification } = props;

    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        if(loading) return;
        setLoading(true);
        try {
            await Api.patchPlan({ business_account_feature_id: accountFeature });
            triggerNotification('Courses enabled', 'success');
            onFeatureAdded(accountFeature);
        } catch (error) {
            console.error(error);
            triggerNotification('We were unable to add courses to your plan - if this problem persists, please contact your consultant.', 'danger');
        }
        setLoading(false);
    }

    const renderFeature = () => {
        let featureTitle, featurePrice;
        switch(accountFeature) {
            case accountFeatures.COURSES:
                featureTitle = 'Courses';
                featurePrice = 'FREE';
                break;
            default:
                return null;
        }
        return (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={2} alignItems="center">
                    <ChalkboardTeacher size={32} />
                    <Typography variant="subtitle1">{featureTitle}</Typography>
                </Stack>
                <Typography variant="body2">{featurePrice}</Typography>
            </Stack>
        )
    }

    return (
        <Modal
            open={props.open}
            onClose={!loading && props.onClose}
            title="Edit Plan"
            maxWidth="xs"
            fullWidth
        >
            <Typography variant="h5" sx={{ mb: 1 }}>Manage Plan</Typography>
            <Typography variant="body2">Feature being added to your plan:</Typography>
            <Divider sx={{ my: 2, backgroundColor: grey[400] }} />
            {renderFeature()}
            <Divider sx={{ my: 2, backgroundColor: grey[400] }} />
            <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                fullWidth
                onClick={handleConfirm}
            >
                Confirm
            </LoadingButton>
        </Modal>
    );
}

export default EditPlanModal;