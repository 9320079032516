import React from 'react';
// Custom components
import Card from 'components/Material/Card';
import CoursesTable from './CoursesTable';
import CoursesCardHeader from './CoursesCardHeader';

function CourseModuleOrderModal(props) {
    return (
        <Card>
            <CoursesCardHeader
                onSearchChange={props.onSearchChange}
                onAddClick={props.onAddClick}
            />
            <CoursesTable {...props} />
        </Card>
    );
}

export default CourseModuleOrderModal;;