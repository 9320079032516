import React, { useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
// Generic components
import Modal from '../Material/Modal';
// API
import Api from 'api/index';

function CourseAdd(props) {
    const { isVisible, setVisible, submit } = props;
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    
    const submitCourse = async () => {
        if (loading) return;

        if(!name || !name.trim() || name.length > 255) {
            setNameError(true);
            return;
        }
        setNameError(false);
        setLoading(true);
        const { data } = await Api.postCourseData(name);
        submit(data.business_course_id);
    }

    const nameMaxLength = 255;
    
    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h5"  sx={{ mb: 2 }}>Create Course</Typography>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    label="Course Name"
                    variant="outlined"
                    required
                    helperText={nameMaxLength - (name?.length || 0)}
                    FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                    inputProps={{
                        maxLength: nameMaxLength
                    }}
                    disabled={loading}
                    error={nameError}
                />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={submitCourse}
                >
                    Create
                </LoadingButton>
            </Box>
        </Modal>
    );
}

export default CourseAdd;