import React from 'react';
// MUI components
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { green, grey } from '@mui/material/colors';
// Custom components
import ImagePlaceholder from 'components/Image/ImagePlaceholder';
// Icons
import { ArrowRight, Users, Circle } from '@phosphor-icons/react';

function CourseRow(props) {
    const { courseObj, onCourseClick, isSm } = props;

    const displayLargeSx = { display: { xs: 'none', sm: 'none', md: 'table-cell' }};
    const displaySmallSx = { display: { sm: 'flex', md: 'none' } };
    
    const renderClassImage = (imgSrc) => {
        if(!imgSrc) return <ImagePlaceholder height={55} width={65} iconSize={22} />;
        return (
            <img
                src={`https://cdn.whatstyle.com/${imgSrc}`}
                alt="Class thumbnail"
                style={{ width: 65, height: 55, objectFit: 'cover' }}
            />
        );
    }

    const renderAttendees = () => {
        return (
            <Stack direction="row" alignItems="center" spacing={isSm ? 0.5 : 1}>
                <Users size={isSm ? 15 : 20} weight="bold" />
                <Typography variant="body2" sx={{ fontSize: { sm: 13, md: "unset" } }}>{courseObj.attendee_count} attendees</Typography>
            </Stack>
        );
    }

    const renderStatus = () => {
        return (
            <Stack direction="row" alignItems="center" spacing={isSm ? 0.3 : 1}>
                <Circle
                    size={isSm ? 10 : 14}
                    weight="fill"
                    color={courseObj.published ? green[600] : grey[500]}
                />
                <Typography variant="body2">{courseObj.published ? 'Published' : 'Draft'}</Typography>
            </Stack>
        )
    }
    
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => onCourseClick(courseObj.business_course_id)}
        >
            <TableCell sx={{ px: 0 }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {renderClassImage(courseObj.business_course_img)}
                    <Stack direction="column">
                        <Typography variant="subtitle1">{courseObj.business_course_name}</Typography>
                        <Stack direction="row" sx={displaySmallSx} spacing={1}>
                            {renderAttendees()}
                            <Typography variant="body2">|</Typography>
                            {renderStatus()}
                        </Stack>
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell sx={displayLargeSx}>
                {renderAttendees()}
            </TableCell>
            <TableCell sx={displayLargeSx}>
                {renderStatus()}
            </TableCell>
            <TableCell sx={{ width: 32, pr: 0 }}>
                <IconButton
                    onClick={() => onCourseClick(courseObj.business_course_id)}
                >
                    <ArrowRight size={16}/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default CourseRow;