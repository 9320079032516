import React from "react";
// MUI components
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
// Custom components
import CurrencyField from "components/Material/CurrencyField";

function Pricing(props) {

    const { isPaid, onIsPaidChange, price, onPriceChange, currencySymbol, editable } = props;

    return (
        <Stack spacing={3} sx={{ pt: 4 }}>
            <FormControl fullWidth>
                <InputLabel id="pricing-select-label">Is this course free or paid?</InputLabel>
                <Select
                    labelId="pricing-select-label"
                    label="Is this course free or paid?"
                    defaultValue={false}
                    value={isPaid}
                    onChange={onIsPaidChange}
                    disabled={!editable}
                >
                    <MenuItem value={false}>Free</MenuItem>
                    <MenuItem value={true}>Paid</MenuItem>
                </Select>
            </FormControl>
            {isPaid && (
                <CurrencyField value={price} disabled={!editable} symbol={currencySymbol} onChange={onPriceChange} />
            )}
            <Alert severity="info">
                Deposit payments are not currently supported. We are working to introduce deposit and late-cancellation/no-show fee payments for courses as quickly as possible - any questions please contact your consultant.
            </Alert>
        </Stack>
    );
}

export default Pricing;