import React from 'react';
// Cropper
import Cropper from 'react-easy-crop';
// Mui components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// Icons
import { FiMinus, FiPlus, FiRotateCw, FiRotateCcw } from 'react-icons/fi';
// Slider
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
// Custom components
import Modal from 'components/Material/Modal';
// Utils
import { getCroppedImg } from './canvasUtils';

class ImageCropper extends React.Component {

    constructor(props) {
        super(props);
        this.cropperContainerRef = React.createRef();
        this.state = {
            loading: false,
            imageSrc: null,
            crop: { x: 0, y: 0 },
            croppedAreaPixels: null,
            rotation: 0,
            zoom: 1,
            aspect: 1,
            cropWidth: 100,
            cropHeight: 100,
            cropMaxWidth: 200,
            cropMaxHeight: 200
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.imageFile !== this.props.imageFile && this.props.adjustCropSize) {
            this.loadImage();
        }
    }

    loadImage() {
        let that = this;
        if(this.props.imageFile) {
            let img = new Image();
            img.onload = function(){
                let imgHeight = this.height;
                if(imgHeight > 320) {
                    imgHeight = 320;
                }
                let imgWidth = this.width;
                console.log(that.cropperContainerRef.current);
                if(that.cropperContainerRef.current && imgWidth > that.cropperContainerRef.current.clientWidth) {
                    imgWidth = that.cropperContainerRef.current.clientWidth + 5;
                }
                that.setState({ cropMaxWidth: imgWidth, cropMaxHeight: imgHeight });
            };
            img.src = this.props.imageFile;

        }
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    onRotationChange = (rotation) => {
        this.setState({ rotation })
    }

    onCropWidthChange = (cropWidth) => {
        this.setState({ cropWidth });
    }

    onCropHeightChange = (cropHeight) => {
        this.setState({ cropHeight });
    }

    async submitImage() {
        this.setState({ loading: true });
        const croppedImage = await getCroppedImg(
            this.props.imageFile,
            this.state.croppedAreaPixels,
            this.state.rotation,
            this.props.xDim,
            this.props.yDim,
            this.props.adjustCropSize
        );
        this.props.submitCroppedImage(croppedImage);
        this.setState({ loading: false });
    }

    render() {
        return (
            <Modal
                open={this.props.visible}
                onClose={() => this.props.toggleVisible()}
                maxWidth="sm"
                fullWidth
                sx={{ zIndex: 1500 }}
            >
                <Box>
                    <Typography variant="h5" sx={{ mb: 2 }}>Crop Image</Typography>
                </Box>
                <Box>
                    <div className={`cropper-container ${this.props.adjustCropSize ? "cropper-container-transparent" : null}`} ref={this.cropperContainerRef}>
                        {this.props.imageFile ?
                            <Cropper
                                image={this.props.imageFile}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                rotation={this.state.rotation}
                                minZoom={1}
                                maxZoom={4}
                                aspect={this.props.aspect ? this.props.aspect : this.state.aspect}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                                cropSize={this.props.adjustCropSize ? { width: this.state.cropWidth, height: this.state.cropHeight } : null}
                            />
                        : null}
                    </div>
                </Box>
                <div style={{ padding: 24 }}>
                    {this.props.adjustCropSize ?
                        <div>
                            <div style={{ marginBottom: 10 }}>
                                <h6>Width</h6>
                                <div className="cropper-control-row">
                                    <FiMinus size={20}/>
                                    <Slider
                                        className="rc-slider-primary"
                                        defaultValue={1}
                                        value={this.state.cropWidth}
                                        onChange={(e) => this.onCropWidthChange(e)}
                                        min={10}
                                        max={this.state.cropMaxWidth}
                                        step={1}
                                        style={{ marginTop: 3, marginLeft: 10, marginRight: 10 }}
                                    />
                                    <FiPlus size={20}/>
                                </div>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <h6>Height</h6>
                                <div className="cropper-control-row">
                                    <FiMinus size={20}/>
                                    <Slider
                                        className="rc-slider-primary"
                                        defaultValue={1}
                                        value={this.state.cropHeight}
                                        onChange={(e) => this.onCropHeightChange(e)}
                                        min={10}
                                        max={this.state.cropMaxHeight}
                                        step={1}
                                        style={{ marginTop: 3, marginLeft: 10, marginRight: 10 }}
                                    />
                                    <FiPlus size={20}/>
                                </div>
                            </div>
                        </div>
                    : null}
                    <div style={{ marginBottom: 10 }}>
                        <h6>Zoom</h6>
                        <div className="cropper-control-row">
                            <FiMinus size={20}/>
                            <Slider
                                className="rc-slider-primary"
                                defaultValue={1}
                                value={this.state.zoom}
                                onChange={(e) => this.onZoomChange(e)}
                                min={1}
                                max={4}
                                step={0.1}
                                on
                                style={{ marginTop: 3, marginLeft: 10, marginRight: 10 }}
                            />
                            <FiPlus size={20}/>
                        </div>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <h6>Orientation</h6>
                        <div className="cropper-control-row">
                            <FiRotateCcw size={20}/>
                            <Slider
                                className="rc-slider-primary"
                                defaultValue={1}
                                value={this.state.rotation}
                                onChange={(e) => this.onRotationChange(e)}
                                min={-180}
                                max={180}
                                step={5}
                                marks={{ 0: 0 }}
                                style={{ marginTop: 3, marginLeft: 10, marginRight: 10 }}
                            />
                            <FiRotateCw size={20}/>
                        </div>
                    </div>
                </div>
                <Box>
                    <Button
                        variant="contained"
                        style={{ width: '100%' }}
                        onClick={() => this.submitImage()}
                    >
                        {this.state.loading ? <CircularProgress color="secondary" size={24.5} /> : "Apply Changes"}
                    </Button>
                </Box>
            </Modal>
        );
    }
}

export default ImageCropper;