import React, { useState } from "react";
// MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
// Custom components
import CourseAttendeeForm from "../CourseAttendeeForm";
import CourseAttendeeFormModal from "../CourseAttendeeFormModal";
import CourseAttendeeTable from "../CourseAttendeeTable";
import CustomerSearch from "components/Customers/CustomerSearch";

function Attendees(props) {
    
    const { attendeeFormComplete, attendeeFormSubmit, attendeeSubmit, attendeeDelete, attendeeCapacity, attendeeData, inviteOnly, editable } = props;

    const [attendeeFormModalVisible, setAttendeeFormModalVisible] = useState(false);

    if(!attendeeFormComplete) {
        return (
            <Box sx={{ pt: 4 }}>
                <CourseAttendeeForm
                    attendeeCapacity={attendeeCapacity}
                    inviteOnly={inviteOnly}
                    onSubmit={attendeeFormSubmit}
                    editable={editable}
                />
            </Box>
        );
    }

    const attendeeTitle = () => {
        if(attendeeCapacity) {
            if(attendeeCapacity === attendeeData?.length) {
                return `Fully booked (${attendeeData?.length} attendees)`;
            }
            if(attendeeCapacity < attendeeData?.length) {

                return `Over-subscribed (${attendeeData?.length - attendeeCapacity} over capacity)`;
            }
            const spacesAvailable = attendeeCapacity - attendeeData?.length;
            return `${spacesAvailable} space${spacesAvailable > 1 ? 's' : ''} available`;
        }
        return `${attendeeData?.length} attendees`;
    }

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="column">
                    <Typography variant="subtitle2">{attendeeTitle()}</Typography>
                </Stack>
                <Button variant="outlined" size="small" onClick={() => setAttendeeFormModalVisible(true)}>Settings</Button>
            </Box>
            {editable && <CustomerSearch label="Add a customer..." onSelect={attendeeSubmit} />}
            {attendeeCapacity && attendeeCapacity < attendeeData?.length && <Alert severity="warning">This course is over-subscribed.</Alert>}
            <CourseAttendeeTable attendeeData={attendeeData} onDelete={attendeeDelete} editable={editable} />
            {attendeeFormModalVisible && (
                <CourseAttendeeFormModal
                    isVisible={attendeeFormModalVisible}
                    setVisible={() => setAttendeeFormModalVisible(!attendeeFormModalVisible)}
                    attendeeCapacity={attendeeCapacity}
                    inviteOnly={inviteOnly}
                    editable={editable}
                    onSubmit={(e) => { setAttendeeFormModalVisible(false); attendeeFormSubmit(e) }}
                />
            )}
        </Stack>
    );
}

export default Attendees;