import React from 'react';
import moment from 'moment';
// MUI components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
// Icons
import { Folder, CaretDown, TrashSimple, PencilSimple } from '@phosphor-icons/react';
// Course components
import CourseClass from './CourseClass';

function CourseModule(props) {
    const { 
        data,
        expanded,
        toggleExpanded,
        addClass,
        updateModule,
        deleteModule,
        updateClass,
        deleteClass,
        isXs,
        editable
    } = props;

    const renderNoClasses = () => {
        return (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography>
                    No classes added yet.
                </Typography>
                {editable && (
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mt: 2 }}
                        size="small"
                        onClick={() => addClass(data.business_course_module_id)}
                    >
                        Add Class
                    </Button>
                )}
            </Box>
        );
    }

    const handleModuleIconButtonClick = (event) => {
        event.stopPropagation();
        switch (event.currentTarget.id) {
            case 'edit-module-button':
                updateModule(data.business_course_module_id);
                break;
            case 'delete-module-button':
                deleteModule(data.business_course_module_id);
                break;
            default:
                console.log('Unknown button clicked');
                break;
        }
    }

    const renderModuleIconButtons = () => {
        const buttonProps = { size: isXs ? "small" : "large" };
        return (
            <Stack direction="row" spacing={isXs ? 0.6 : 1}>
                <IconButton {...buttonProps} id="edit-module-button" onClick={handleModuleIconButtonClick}>
                    <PencilSimple size={20} />
                </IconButton>
                <IconButton {...buttonProps} id="delete-module-button" onClick={handleModuleIconButtonClick}>
                    <TrashSimple size={20} />
                </IconButton>
            </Stack>
        )
    }

    return (
        <Accordion sx={{ boxShadow: 0, borderTop: '1px solid rgba(0, 0, 0, .125)' }} expanded={expanded} onChange={() => toggleExpanded(data.business_course_module_id)}>
            <AccordionSummary expandIcon={<CaretDown size={20} weight="bold" />}>
                <Box sx={{ p: { xs: 1, sm: 2 }, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                        <Folder size={20} weight="bold" />
                        <Typography sx={{ fontSize: isXs ? 17 : 19, fontWeight: '600' }}>{data.business_course_module_name}</Typography>
                    </Box>
                    {editable && renderModuleIconButtons()}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)' }}>
                {(!data.classData || data.classData?.filter(x => x.state !== 3).length === 0) && renderNoClasses()}
                {data.classData?.filter(x => x.state !== 3).sort((a, b) => moment(a.business_class_start, 'YYYY-MM-DD HH:mm:ss') - moment(b.business_class_end, 'YYYY-MM-DD HH:mm:ss')).map((classObj) => (
                    <CourseClass
                        key={`course-class-${classObj.business_class_id}`}
                        data={classObj}
                        updateClass={updateClass}
                        deleteClass={deleteClass}
                        editable={editable}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

export default CourseModule;