import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
// Icons
import { Image } from '@phosphor-icons/react'
import { Stack } from '@mui/material';

export default function Placeholder(props) {
    const { height, width, iconSize } = props;
    return (
        <Box sx={{ display: 'flex', background: grey[300], borderRadius: 1, width, height }}>
            <Stack sx={{ width: '100%' }} direction="row" justifyContent="center" alignItems="center">
                <Image
                    size={iconSize}
                    color={grey[500]}    
                />
            </Stack>
        </Box>
    );
}