import React from 'react';

// MUI components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function CourseTabs(props) {
    const { activeTab, setActiveTab, isXs } = props;
    
    return (
        <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            variant={isXs ? "fullWidth" : "scrollable"}
            scrollButtons="auto"
        >
            <Tab label="Overview" value="overview" />
            <Tab label="Attendees" value="attendees" />
            <Tab label="Pricing" value="pricing" />
            <Tab label="Settings" value="settings" />
        </Tabs>
    );
}

export default CourseTabs;