import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    FormText,
    Spinner,
    Alert
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";
import Compressor from 'compressorjs';

import Api from '../../api/index';

import { FiImage, FiX } from 'react-icons/fi';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import Switch from "react-switch";
import Select from 'react-select';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";
// Utilities
import formatting from 'utilities/formatting';
import withRouter from "utilities/withRouter";

class StaffAdd extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            imageCropperVisible: false,
            imageFile: null,
            imageFileCropped: null,
            firstname: null,
            lastname: null,
            position: null,
            contact_number: null,
            contact_number_ios: null,
            email: null,
            bookable: true,
            locationOptions: [],
            locationTags: []
        };
        this.toggleImageCropper = this.toggleImageCropper.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.submitCroppedImage = this.submitCroppedImage.bind(this);
        this.staffImgClick = this.staffImgClick.bind(this);
        this.handleRemoveStaffImg = this.handleRemoveStaffImg.bind(this);
    }

    componentDidMount() {
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id,
                address: formatting.getFormattedBusinessLocationAddress(businessLocationObj)
            });
        });
        this.setState({ locationOptions });
    }

    handleChange(e, name) {
        if(name === 'firstname') {
            this.setState({ firstname: e.target.value });
        } else if(name === 'lastname') {
            this.setState({ lastname: e.target.value });
        } else if(name === 'position') {
            this.setState({ position: e.target.value });
        } else if(name === 'bio') {
            this.setState({ bio: e.target.value });
        } else if(name === 'contact_number') {
            this.setState({ contact_number: e[0], contact_number_iso: e[1].countryCode });
        } else if(name === 'email') {
            this.setState({ email: e.target.value });
        } else if(name === 'bookable') {
            this.setState({ bookable: e });
        } else if(name === 'location') {
            let locationTags = [...this.state.locationTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = locationTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        locationTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                locationTags.forEach((locationTagObj, locationTagIndex) => {
                    let foundObj = e.find(x => x.id === locationTagObj.id);
                    if(!foundObj) {
                        locationTags = locationTags.filter(x => x.id !== locationTagObj.id);
                    }
                });
            } else {
                // All tags removed
                locationTags = [];
            }
            this.setState({ locationTags });
        }
    }

    toggleImageCropper() {
        this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
    }

    async onFileChange(e) {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ imageFile: imageDataUrl, imageCropperVisible: true });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    getNormalizedFile(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    submitCroppedImage(file) {
        this.setState({ imageFileCropped: file, imageCropperVisible: false });
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async submitStaff() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.firstname || this.state.firstname.length === 0) {
            this.setState({ error: 'A first name is required.' });
            return;
        }
        if(!this.state.lastname || this.state.lastname.length === 0) {
            this.setState({ error: 'A last name is required.' });
            return;
        }
        if(!this.state.position || this.state.position.length === 0) {
            this.setState({ error: 'A position is required.' });
            return;
        }
        if(this.state.email && this.state.email.length > 0 && !this.validateEmail(this.state.email)){
            this.setState({ error: 'Invalid email address.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let contact_number = null;
            if(this.state.contact_number && this.state.contact_number.length > 0) {
                contact_number = '+' + this.state.contact_number;
            }
            let locationTags = [...this.state.locationTags];
            let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
            if(enabledBusinessLocations && enabledBusinessLocations.length === 1 && locationTags.length === 0) {
                locationTags.push({ id: enabledBusinessLocations[0].business_location_id });
            }
            let data = {
                staff_img: this.state.imageFileCropped,
                staff_firstname: this.state.firstname,
                staff_lastname: this.state.lastname,
                staff_position: this.state.position,
                staff_bio: this.state.bio,
                staff_email: this.state.email,
                staff_phone: contact_number,
                staff_phone_iso: this.state.contact_number_iso,
                staff_bookable: this.state.bookable,
                staff_locations: locationTags
            };
            await Api.addStaff(data);
            this.props.triggerNotification("Staff member added successfully.", "success", "bc", 4);
            this.props.navigate('/admin/staff');
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    handleRemoveStaffImg(e) {
        e.preventDefault();
        this.setState({ imageFileCropped: null });
    }

    staffImgClick(e) {
        if(e.defaultPrevented) return
        this.staffImgInput.click();
    }

    formatLocationOptionLabel(e) {
        return (
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left' }}>
                <div style={{ fontSize: 13 }}>{e.label}</div>
                {window.innerWidth >= 700 ?
                    <div className="text-muted" style={{ fontSize: 11 }}>{e.address}</div>
                : null}
            </div>
        );
    };

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Add Staff</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={6} xl={4} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ marginBottom: 15 }}>
                                    <input type="file" ref={fileInput => this.staffImgInput = fileInput} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={this.onFileChange} style={{ display: 'none' }} />
                                    <div className="staff-img-container" onClick={this.staffImgClick}>
                                        {this.state.imageFileCropped ?
                                            <div>
                                                <img alt="StaffImg" src={this.state.imageFileCropped} className="staff-img-placeholder"/>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="btn-round btn-icon"
                                                    style={{ float: 'right', marginBottom: 0, marginTop: -30, borderRadius: '50%' }}
                                                    onClick={this.handleRemoveStaffImg}
                                                >
                                                    <FiX size={14}/>
                                                </Button>
                                            </div>
                                        :
                                            <div className="staff-img-icon-container">
                                                <FiImage size={30}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <label>First name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.props.serviceName}
                                                onChange={(e) => this.handleChange(e, 'firstname')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <label>Last name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.props.serviceName}
                                                onChange={(e) => this.handleChange(e, 'lastname')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Position <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.props.serviceName}
                                                onChange={(e) => this.handleChange(e, 'position')}
                                                placeholder={'e.g. Senior Stylist'}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Bio</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                style={{ padding: 10 }}
                                                value={this.props.bookingNotes}
                                                onChange={(e) => this.handleChange(e, 'bio')}
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            The staff bio provides a space for you to introduce your staff member and detail their education, training, experience and skills. This information will be shown to your customers.
                                        </FormText>
                                    </Col>
                                </Row>
                                {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length > 1 ?
                                    <div>
                                        <hr/>
                                        <Row>
                                            <Col md={12}>
                                                <h6>Locations</h6>
                                                <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                                    Choose the locations where this staff member works.
                                                </FormText>
                                            </Col>
                                            <Col xs={12} sm={12} md={12}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        isMulti
                                                        options={this.state.locationOptions}
                                                        formatOptionLabel={this.formatLocationOptionLabel}
                                                        placeholder={'Choose working locations...'}
                                                        onChange={(e) => this.handleChange(e, 'location')}
                                                        captureMenuScroll={true}
                                                        controlShouldRenderValue={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                : null}
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Contact Details</h6>
                                        <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                            These contact details are for internal purposes only; they will not be shared with your customers.
                                        </FormText>
                                    </Col>
                                    <Col xs={12} sm={6} md={6}>
                                        <label>Contact number</label>
                                        <FormGroup>
                                            <PhoneInput
                                                className="contact-input"
                                                country={'gb'}
                                                placeholder="Contact Number"
                                                inputClass="form-control"
                                                onChange={(value, country, e, formattedValue) => this.handleChange([value, country, e, formattedValue], 'contact_number')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6}>
                                        <label>Email Address</label>
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                onChange={(e) => this.handleChange(e, 'email')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Preferences</h6>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label">Bookable</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'bookable') }}
                                                checked={this.state.bookable}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                            <FormText color="muted" style={{ marginTop: '1rem' }}>
                                                Should customers be able to book with this staff member?
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Button color="primary" onClick={() => this.submitStaff()}>
                                    {this.state.loading ? <Spinner color="success" size="sm" /> : 'Add Staff'}
                                </Button>
                            </Col>
                        </Row>
                        <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                            <span>
                                {this.state.error}
                            </span>
                        </Alert>
                    </Col>
                </Row>
            </div>
            <ImageCropper
                visible={this.state.imageCropperVisible}
                toggleVisible={this.toggleImageCropper}
                imageFile={this.state.imageFile}
                submitCroppedImage={this.submitCroppedImage}
                xDim={300}
                yDim={300}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        business: state.business,
        businessLocation: state.businessLocation
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffAdd));