import React from 'react';
// Mui components
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import time from "constants/time";

const TimePicker = (props) => {
    const { label, value, defaultValue, ...rest } = props;
    return (
        <Autocomplete
            options={time}
            defaultValue={defaultValue ?? time.find((option) => option.value === "09:00")}
            renderInput={(params) => <TextField {...params} label={label} />}
            value={value ? time.find((option) => option.minutes === value) : undefined}
            {...rest}
        />
    )
};

export default TimePicker;