import React, { useEffect, useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
// Icons
import { Folder } from '@phosphor-icons/react';
// Course components
import CourseModule from './CourseModule';

function CourseModuleList(props) {
    const { moduleData, expandedModules, addModule, ...rest } = props;

    const [sortedModuleData, setSortedModuleData] = useState([]);

    useEffect(() => {
        setSortedModuleData(moduleData?.sort((a, b) => a.display_order - b.display_order));
    }, [moduleData]);

    const renderNoModules = () => {
        return (
            <Stack flex gap={1.5} justifyContent="center" sx={{ textAlign: 'center', backgroundColor: grey[100], p: 2, borderRadius: 1 }}>
                <Box>
                    <Folder size={46} />
                </Box>
                <Typography>
                    Get started by adding a module.
                </Typography>
                <Box>
                    <Button variant="outlined" color="primary" size="small" onClick={addModule}>Add module</Button>
                </Box>
            </Stack>
        );
    }

    return (
        <Box sx={{ border: '1px solid lightgray', borderRadius: 1 }}>
            {sortedModuleData && sortedModuleData?.map((moduleObj,) => (
                <CourseModule
                    key={`course-module-${moduleObj.business_course_module_id}`}
                    data={moduleObj}
                    expanded={expandedModules?.includes(moduleObj.business_course_module_id)}
                    {...rest}
                />
            ))}
            {sortedModuleData?.length === 0 && renderNoModules()}
        </Box>
    );
}

export default CourseModuleList;