import React from 'react';

import Box from '@mui/material/Box';

import ColourPicker from './ColourPicker';

export default function ColourInput(props) {
    const { colour, submitColour, disabled } = props;

    const [colorPickerVisible, setColorPickerVisible] = React.useState(false);

    return (
        <React.Fragment>
            <Box
                sx={{ backgroundColor: colour, width: 30, height: 30, border: '1px solid rgb(154, 154, 154)', borderRadius: 1.5, cursor: 'pointer' }}
                onClick={() => {
                    if(disabled) return;
                    setColorPickerVisible(true);
                }}
            />
            {colorPickerVisible && (
                <ColourPicker
                    visible={colorPickerVisible}
                    toggleVisible={() => setColorPickerVisible(!colorPickerVisible)}
                    initialColor={colour}
                    onSubmit={(e) => { setColorPickerVisible(false); submitColour(e) }}
                    remove
                />
            )}
        </React.Fragment>
    );
}