import React, { useState } from 'react';
// MUI components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { grey } from '@mui/material/colors';
// Assets
import stylerLogo from '../../assets/img/styler_logo_secondary.svg';
import CoursesLandingGraphic from '../../assets/img/CoursesLandingGraphic.png';
// Constants
import accountFeatures from 'constants/accountFeatures';
// Custom components
import EditPlanModal from '../Plan/EditPlanModal';

function CoursesLanding(props) {
    const { userRole, ...rest } = props;

    const [editPlanModalVisible, setEditPlanModalVisible] = useState(false);

    const editBillingPermission = userRole?.billing?.update === true;

    return (
        <Box sx={{ justifyContent: 'center', display: 'flex', flex: 1, my: 4 }} >
            <Grid container spacing={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={11} sm={10} md={5} lg={4} xl={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <Box>
                            <img src={stylerLogo} alt="Styler Logo" style={{ width: 250 }} />
                            <Typography variant="h3" sx={{ fontWeight: '600', letterSpacing: '0.22em', color: '#0a122b' }}>Courses</Typography>
                            <Chip label="BETA" color="info" sx={{ fontWeight: '900', mt: 2 }} />
                        </Box>
                        <Box>
                            <Typography variant="h6">Group bookings, tailored training, and more—fully integrated with your booking platform, app and widget.</Typography>
                        </Box>
                        <Divider sx={{ backgroundColor: grey[900] }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 6, alignItems: 'center' }}>
                            <Button variant="contained" color="primary" disabled={!editBillingPermission} onClick={() => setEditPlanModalVisible(true)}>Subscribe</Button>
                            <Stack>
                                <Typography variant="caption" sx={{ lineHeight: 1, color: grey[600] }}>Price/month:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 600 }}>FREE *</Typography>
                            </Stack>
                        </Box>
                        <Box>
                            <Typography variant="caption">* Styler Courses are FREE in BETA until 31st March. You will be notified of any price changes before being charged.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={11} sm={10} md={5} lg={4} xl={3}>
                    <Box>
                        <img src={CoursesLandingGraphic} alt="Courses" style={{ width: '100%' }} />
                    </Box>
                </Grid>
            </Grid>
            <EditPlanModal
                open={editPlanModalVisible}
                onClose={() => setEditPlanModalVisible(false)}
                accountFeature={accountFeatures.COURSES}
                {...rest}
            />
        </Box>
    );
}

export default CoursesLanding;