import React from 'react';
// MUI components
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
// Icons
import { ChalkboardTeacher } from '@phosphor-icons/react';
// Custom components
import CourseRow from './CourseRow';

function CoursesTable(props) {
    const { courseData, loading, navigate, onAddClick } = props;
    const navigateToCourse = (courseId) => {
        navigate(`/admin/course?id=${courseId}`);
    }
    const isSm = useMediaQuery(theme => theme.breakpoints.down("md"));
    return (
        <React.Fragment>
            <TableContainer sx={{ pt: 1 }}>
                <Table style={{ marginBottom: 0 }}>
                    <TableBody>
                        {courseData.map((courseObj,) =>
                            <CourseRow
                                key={`courseRow${courseObj.business_course_id}`}
                                courseObj={courseObj}
                                onCourseClick={navigateToCourse}
                                isSm={isSm}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress color="secondary" size={30} />
                </Box>
            )}
            {!loading && courseData.length === 0 && (
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }} gap={1}>
                    <ChalkboardTeacher size={50} />
                    <Box>
                        <Typography>No courses found.</Typography>
                        <Typography variant="subtitle2">Add your first course.</Typography>
                    </Box>
                    <Button variant="contained" size="small" onClick={onAddClick}>Add</Button>
                </Stack>
            )}
        </React.Fragment>
    );
}

export default CoursesTable;