import React from 'react';
// MUI components
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function CoursesCardHeader(props) {
    const { onAddClick, onSearchChange } = props;
    return(
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={4}>
            <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => onSearchChange(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={onAddClick}>Add</Button>
        </Stack>
    );
}

export default CoursesCardHeader;