import React, { useState, useRef } from 'react';
// MUI components
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
// Compressor
import Compressor from 'compressorjs';
// Icons
import { Image, Trash } from '@phosphor-icons/react';
// Custom components
import ImageCropper from 'components/ImageCropper/ImageCropper';

export default function ImageDropZone(props) {
    
    const { imageData, submitImageData, disabled } = props;

    const imgInputRef = useRef(null);

    const [imageCropperVisible, setImageCropperVisible] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    
    const dropZoneClick = (e) => {
        if(e.defaultPrevented) return;
        if(disabled) return;
        imgInputRef.current?.click();
    }

    const deleteClick = (e) => {
        e.preventDefault();
        if(disabled) return;
        if(imageData) {
            submitImageData(null);
            setImageFile(null);
        } else {
            if(imageFile) {
                setImageFile(null);
            }
        }
    }

    const getNormalizedFile = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }
    
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    const onFileChange = async (e) => {
        if(e.target?.files?.length === 1) {
            const imageDataUrl = await readFile(e.target.files[0]);
            setImageFile(imageDataUrl);
            setImageCropperVisible(true);
        }
    }

    const submitCroppedImage = (file) => {
        setImageCropperVisible(false);
        submitImageData(file);
    }

    return (
        <React.Fragment>
            <Box
                sx={{ backgroundColor: grey[300], borderRadius: 1, p: 1, cursor: 'pointer', minHeight: 160, textAlign: 'center', alignContent: 'center', position: 'relative', '&:hover': { backgroundColor: grey[200] } }}
                onClick={dropZoneClick}
            >
                {imageData ? (
                    <img src={imageData} alt="Course Profile" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                    <Image size={48} color={grey[500]} />
                )}
                <input type="file" ref={imgInputRef} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={onFileChange} style={{ display: 'none' }} />
                {imageData && !disabled && (
                    <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
                        <IconButton aria-label="Delete image" size="medium" color="secondary" onClick={deleteClick}>
                            <Trash />
                        </IconButton>
                    </div>
                )}
            </Box>
            {imageCropperVisible && (
                <ImageCropper
                    visible={imageCropperVisible}
                    toggleVisible={() => setImageCropperVisible(!imageCropperVisible)}
                    imageFile={imageFile}
                    submitCroppedImage={submitCroppedImage}
                    xDim={1000000}
                    yDim={1000000}
                    aspect={4/3}
                    adjustCropSize={false}
                />
            )}
        </React.Fragment>
    );
}