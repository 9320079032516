import React, { useState } from 'react';
// MUI components
import TextField from '@mui/material/TextField';

function CourseTitle(props) {
    const { title, updateTitle, editable } = props;
    const [isFocussed, setFocussed] = useState(false);
    return (
        <TextField
            value={title}
            disabled={!editable}
            onChange={e => updateTitle(e.target.value)}
            variant="standard"
            fullWidth
            onFocus={() => setFocussed(true)}
            onBlur={() =>  setTimeout(() => setFocussed(false), 200)}
            inputProps={{
                style: { fontSize: 26, fontWeight: 700 }
            }}
            sx={{
                '&:hover': { borderBottom: '1px solid #000000' },
                input: { textAlign: { xs: 'center', sm: 'inherit' } }
            }}
            InputProps={{ disableUnderline: !isFocussed }}
        />
    );
}

export default CourseTitle;