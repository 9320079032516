import React from 'react';
// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
// Icons
import { User, Envelope, Phone, TrashSimple } from '@phosphor-icons/react'
// Custom components
import UserAvatar from '../Material/UserAvatar';

function CourseAttendeeTable(props) {
    const { attendeeData, onDelete, editable } = props;

    const displayLargeSx = { display: { xs: 'none', sm: 'none', md: 'table-cell' }};
    const displaySmallSx = { display: { sm: 'table-cell', md: 'none' } };
    
    if(attendeeData?.length === 0) {
        return (
            <Box sx={{ p: 2, backgroundColor: '#ebebeb', borderRadius: 1, textAlign: 'center' }}>
                <User size={40}/>
                <Typography variant="subtitle1">No attendees yet</Typography>
            </Box>
        );
    }

    const renderCustomerContactDetail = (contactDetail, contactType, size="md") => {
        // Size
        let iconSize = 18;
        let textVariant = "body2";
        if(size === "sm") {
            iconSize = 16;
            textVariant = "caption";
        }
        // Icon
        let contactIcon;
        switch(contactType) {
            case "email":
                contactIcon = <Envelope size={iconSize}/>;
                break;
            case "phone":
                contactIcon = <Phone size={iconSize}/>;
                break;
            default:
                contactIcon = <User size={iconSize}/>;
        }
        return (
            <Box display="flex" gap={1} alignItems="center">
                {contactIcon}
                <Typography variant={textVariant}>{contactDetail ? contactDetail : '----------------'}</Typography>
            </Box>
        );
    }
    
    return (
        <TableContainer sx={{ pt: 1 }}>
            <Table style={{ marginBottom: 0 }}>
                <TableBody>
                    {attendeeData.map((customerObj,) => (
                        <TableRow
                            key={`attendeeRow${customerObj.user_id}`}
                        >
                            <TableCell>
                                <Box display="flex" alignItems="center">
                                    <Box sx={{ mr: 2 }}>
                                        <UserAvatar name={`${customerObj.user_firstname} ${customerObj.user_lastname}`}/>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle1">{`${customerObj.user_firstname} ${customerObj.user_lastname}`}</Typography>
                                        <Box sx={displaySmallSx}>
                                            {renderCustomerContactDetail(customerObj.user_email, 'email', 'sm')}
                                            {renderCustomerContactDetail(customerObj.user_phone, 'phone', 'sm')}
                                        </Box>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell sx={displayLargeSx}>
                                {renderCustomerContactDetail(customerObj.user_email, 'email')}
                            </TableCell>
                            <TableCell sx={displayLargeSx}>
                                {renderCustomerContactDetail(customerObj.user_phone, 'phone')}
                            </TableCell>
                            {editable && (
                                <TableCell sx={{ width: 32 }}>
                                    <IconButton size="large" id="delete-attendee-button" onClick={() => onDelete(customerObj.user_id)}>
                                        <TrashSimple size={20} />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CourseAttendeeTable;