import React from 'react';
// MUI components
import Box from '@mui/material/Box';
// Couse components
import CourseImage from './CourseImage';
import CourseTabs from './CourseTabs';
import CourseTitle from './CourseTitle';
import CourseOptions from './CourseOptions';

function CourseHeader(props) {  
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, gap: 3 }}>
            <Box sx={{ alignSelf: { xs: 'center', sm: 'inherit' }}}>
                <CourseImage imgSrc={props.image} />
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'flex' }, flex: 1, justifyContent: 'space-between' }}>
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                >
                    <CourseTitle
                        title={props.title}
                        updateTitle={props.updateTitle}
                        editable={props.editable}
                    />
                    <CourseTabs {...props} />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'inherit' } }}>
                    <CourseOptions {...props} />
                </Box>
            </Box>
        </Box>
    );
}

export default CourseHeader;