import React, { useEffect, useState } from 'react';
import moment from 'moment';
// MUI components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
// Generic components
import Modal from 'components/Material/Modal';
import TimePicker from 'components/Material/TimePicker';
import DatePicker from 'components/Material/DatePicker';
import DurationPicker from 'components/Material/DurationPicker';
import ImageDropZone from 'components/ImageCropper/ImageDropZone';
import BusinessLocationSelect from 'components/Material/BusinessLocationSelect';
import StaffSelect from 'components/Material/StaffSelect';

function CourseClassForm(props) {
    const { isVisible, moduleId: defaultModuleId, setVisible, submitClass, moduleData, businessLocationData, staffData, classData, editable } = props;
    // Is form controlled
    const isControlled = classData ? true : false;
    // Field states
    const [name, setName] = useState('');
    const [moduleId, setModuleId] = useState(defaultModuleId ?? moduleData[0].business_course_module_id);
    const [description, updateDescription] = useState('');
    const [image, updateImage] = useState(null);
    const [imageState, updateImageState] = useState(0);
    const [date, setDate] = useState(undefined);
    const [time, setTime] = useState(540);
    const [duration, setDuration] = useState(60);
    const [businessLocation, setBusinessLocation] = useState(businessLocationData[0].business_location_id);
    const [staff, setStaff] = useState([]);
    // Form state
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!classData) return;
        setName(classData.business_class_name);
        setModuleId(classData.business_course_module_id);
        updateDescription(classData.business_class_description);
        updateImage(classData.business_class_img);
        // Parse start date and time
        const classStartMoment = moment(classData.business_class_start, 'YYYY-MM-DD HH:mm:ss');
        const classEndMoment = moment(classData.business_class_end, 'YYYY-MM-DD HH:mm:ss');
        const durationInMinutes = classEndMoment.diff(classStartMoment, 'minutes');
        setDate(classStartMoment);
        setTime(classStartMoment.hours() * 60 + classStartMoment.minutes());
        setDuration(durationInMinutes);
        // Set business location
        setBusinessLocation(classData.business_location_id);
        // Set staff
        setStaff(classData.staff_map);
    }, [classData]);

    const nameMaxLength = 255;

    const submitForm = () => {
        if (loading) return;
        // Validate name
        if(!name || !name.trim() || name.length > nameMaxLength) {
            setErrorMsg('A class name is required and must be less than 255 characters.');
            return;
        }
        // Validate module
        if(!moduleId) {
            if(moduleData?.length > 1) {
                setErrorMsg('A module is required.');
                return;
            } else if(moduleData?.length === 1) {
                setModuleId(moduleData[0].business_course_module_id);
            }
        }
        // Validate date
        if(!date) {
            setErrorMsg('A date is required.');
            return;
        }
        // Validate time
        if(!time) {
            setErrorMsg('A time is required.');
            return;
        }
        // Validate duration
        if(!duration) {
            setErrorMsg('A duration is required.');
            return;
        }
        // Validate business location
        if(!businessLocation) {
            setErrorMsg('A business location is required.');
            return;
        }
        // Submit form
        setLoading(true);
        submitClass({
            id: classData?.business_class_id,
            name,
            moduleId,
            previousModuleId: classData?.business_course_module_id,
            description,
            image,
            imageState,
            date,
            time,
            duration,
            businessLocationId: businessLocation,
            staffIds: staff
        });
    }

    return (
        <Modal
            open={isVisible}
            onClose={setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>{isControlled ? 'Edit' : 'Add'} Class</Typography>
                <TextField
                    value={isControlled ? name : undefined}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    label="Class Name"
                    variant="outlined"
                    required
                    helperText={nameMaxLength - (name?.length || 0)}
                    FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                    inputProps={{
                        maxLength: nameMaxLength
                    }}
                    disabled={!editable}
                />
                {moduleData?.length > 1 && ((isControlled && moduleId) || !isControlled) && (
                    <FormControl fullWidth>
                        <InputLabel id="module-select-label">Module</InputLabel>
                        <Select
                            fullWidth
                            value={isControlled ? moduleId : undefined}
                            defaultValue={defaultModuleId ?? moduleData[0].business_course_module_id}
                            onChange={(e) => setModuleId(e.target.value)}
                            labelId="module-select-label"
                            label="Module"
                            variant="outlined"
                            disabled={!editable}
                        >
                            {moduleData.map((moduleObj) => {
                                return (
                                    <MenuItem
                                        key={`module-${moduleObj.business_course_module_id}-menu-item`}
                                        value={moduleObj.business_course_module_id}
                                    >
                                        {moduleObj.business_course_module_name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )}
                <TextField
                    id="description"
                    label="Description"
                    multiline
                    minRows={3}
                    maxRows={10}
                    value={description}
                    onChange={e => updateDescription(e.target.value)}
                    disabled={!editable}
                />
                <Box>
                    <Typography variant="subtitle2">Image</Typography>
                    <ImageDropZone
                        imageData={image}
                        submitImageData={(e) => {
                            updateImage(e);
                            updateImageState(e ? 2 : 3);
                        }}
                        disabled={!editable}
                    />
                </Box>
                <Divider/>
                {((isControlled && date) || !isControlled) && (
                    <DatePicker
                        label="Date *"
                        value={isControlled ? date : undefined}
                        onChange={e => setDate(e)}
                        disabled={!editable}
                    />
                )}
                {((isControlled && time) || !isControlled) && (
                    <FormControl fullWidth>
                        <TimePicker
                            label="Time *"
                            variant="outlined"
                            value={time}
                            onChange={(_, e) => setTime(e.minutes)}
                            disabled={!editable}
                        />
                    </FormControl>
                )}
                {((isControlled && duration) || !isControlled) && (
                    <FormControl fullWidth>
                        <DurationPicker
                            label="Duration *"
                            variant="outlined"
                            value={duration}
                            onChange={(_, e) => setDuration(e.value)}
                            disabled={!editable}
                        />
                    </FormControl>
                )}
                <Divider/>
                {((isControlled && businessLocation) || !isControlled) && (
                    <FormControl fullWidth>
                        <InputLabel id="business-location-select-label">Location *</InputLabel>
                        <BusinessLocationSelect
                            labelId="business-location-select-label"
                            variant="outlined"
                            label="Location *"
                            businessLocationData={businessLocationData}
                            value={businessLocation}
                            onChange={(e) => setBusinessLocation(e.target.value)}
                            disabled={!editable}
                        />
                    </FormControl>
                )}
                <FormControl fullWidth>
                    <InputLabel id="staff-select-label">Staff</InputLabel>
                    <StaffSelect
                        staffData={staffData}
                        labelId="staff-select-label"
                        variant="outlined"
                        label="Staff"
                        value={staff}
                        onChange={(e) => setStaff(e.target.value)}
                        disabled={!editable}
                    />
                </FormControl>
                {errorMsg && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {errorMsg}
                    </Alert>
                )}
                {editable && (
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={loading}
                        onClick={submitForm}
                    >
                        {isControlled ? 'Update' : 'Add'}
                    </LoadingButton>
                )}
            </Box>
        </Modal>
    );
}

export default CourseClassForm;