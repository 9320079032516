import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// MUI components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
// Generic components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Card from "components/Material/Card";
import ConfirmDeleteModal from 'components/ConfirmDeleteModal/ConfirmDeleteModal';
// Course components
import CourseHeader from "components/Courses/CourseHeader";
import OverviewTab from 'components/Courses/Tabs/Overview';
import AttendeesTab from 'components/Courses/Tabs/Attendees';
import PricingTab from 'components/Courses/Tabs/Pricing';
import SettingsTab from 'components/Courses/Tabs/Settings';
// Constants
import currency from 'constants/currency';
import formattingUtils from 'utilities/formatting';
// API
import Api from 'api/index';

function Course(props) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('overview');
    const [attendeeFormComplete, setAttendeeFormComplete] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('£');
    const [courseData, setCourseData] = useState(undefined);
    const [deleteCourseModalVisible, setDeleteCourseModalVisible] = useState(false);
    
    const editable = props?.userRole?.course?.update ?? false;
    const deletable = props?.userRole?.course?.delete ?? false;
    const isXs = useMediaQuery(theme => theme.breakpoints.down("sm"));

    useEffect(() => {
        // Load course data
        loadCourseData();
    }, []);

    const loadCourseData = async (setLoading=true) => {
        if(setLoading) {
            props.actions.loadLoadingSpinner(true);
        }
        const query = new URLSearchParams(window.location.search);
        const courseId = query.get('id');
        if(!courseId) {
            props.actions.loadLoadingSpinner(false);
            navigate('/admin/courses');
            return;
        }
        try {
            const { data } = await Api.getCourseData(courseId);
            const { courseData } = data;
            courseData.business_course_img_state = 0;
            courseData.business_course_img = courseData.business_course_img ? `https://cdn.whatstyle.com/${courseData.business_course_img}` : null;
            for(const moduleObj of courseData.moduleData) {
                moduleObj.state = 0;
                for(const classObj of moduleObj.classData) {
                    classObj.state = 0;
                    classObj.business_class_img = classObj.business_class_img ? `https://cdn.whatstyle.com/${classObj.business_class_img}` : null;
                    classObj.business_class_img_state = 0;
                }
            }
            setCourseData(courseData);
            if(courseData.business_course_attendee_limit > 0 || courseData.attendeeData?.length > 0) setAttendeeFormComplete(true);
        } catch(e) {
            if(e?.response?.data?.message) {
                props.triggerNotification(e.response.data.message, "danger");
                navigate('/admin/courses');
            } else {
                props.triggerNotification("An error occured while retrieving course data. If this problem persists, please contact Styler support at support@styler.digital.", "danger");
            }
        }
        if(setLoading) {
            props.actions.loadLoadingSpinner(false);
        }
    };

    useEffect(() => {
        const currencyObj = currency.find(x => x.id === props.business.currency_id);
        if(currencyObj) setCurrencySymbol(currencyObj.symbol);
    }, [props.business.currency_id]);

    const updateCourseTitle = (value) => {
        setCourseData({ ...courseData, business_course_name: value });
    }

    const updateCourseDescription = (value) => {
        setCourseData({ ...courseData, business_course_description: value });
    }

    const updateCourseImage = (value) => {
        setCourseData({ ...courseData, business_course_img: value, business_course_img_state: value ? 2 : 3 });
    }

    const updateCourseColour = (value) => {
        setCourseData({ ...courseData, business_course_colour: value });
    }

    const updateCoursePublished = (value) => {
        setCourseData({ ...courseData, published: value });
    }

    const attendeeFormSubmit = (attendeeObj) => {
        setCourseData({
            ...courseData,
            business_course_attendee_limit: attendeeObj.attendeeCapacity,
            business_course_attendee_invite_only: attendeeObj.inviteOnly
        });
        setAttendeeFormComplete(true);
    };

    const attendeeSubmit = (attendeeObj) => {
        const courseDataCopy = { ...courseData };
        const attendeeIndex = courseDataCopy['attendeeData'].findIndex(x => x.user_id === attendeeObj.user_id);
        if(attendeeIndex > -1) {
            if(courseDataCopy['attendeeData'][attendeeIndex].state === 3) {
                courseDataCopy['attendeeData'][attendeeIndex].state = 0;
                setCourseData(courseDataCopy);
                return;
            }
            props.triggerNotification('This customer is already enrolled.', "danger");
            return;
        };
        attendeeObj.state = 1;
        courseDataCopy['attendeeData'].push(attendeeObj);
        setCourseData(courseDataCopy);
    }

    const attendeeDelete = (userId) => {
        const courseDataCopy = { ...courseData };
        const attendeeIndex = courseDataCopy['attendeeData'].findIndex(x => x.user_id === userId);
        if(courseDataCopy['attendeeData'][attendeeIndex].state === 1) {
            courseDataCopy['attendeeData'].splice(attendeeIndex, 1);
        } else {
            courseDataCopy['attendeeData'][attendeeIndex].state = 3;
        }
        setCourseData(courseDataCopy);
    }

    const getPlaceholderId = (array) => {
        const minId = Math.min(0, ...array);
        return minId - 1;
    }
    
    const submitModule = (moduleObj) => {
        const courseDataCopy = { ...courseData }; 
        if(moduleObj.business_course_module_id) {
            const moduleIndex = courseDataCopy['moduleData'].findIndex(x => x.business_course_module_id === moduleObj.business_course_module_id);
            courseDataCopy['moduleData'][moduleIndex].business_course_module_name = moduleObj.business_course_module_name;
            courseDataCopy['moduleData'][moduleIndex].business_course_module_description = moduleObj.business_course_module_description;
            courseDataCopy['moduleData'][moduleIndex].state = 2;
            setCourseData(courseDataCopy);
            return;
        }
        courseDataCopy['moduleData'].push({
            ...moduleObj,
            business_course_module_id: getPlaceholderId(courseDataCopy['moduleData'].map(x => x.business_course_module_id)),
            display_order: courseData['moduleData'].length,
            classData: [],
            state: 1
        });
        setCourseData(courseDataCopy);
    }

    const submitClass = (classObj) => {
        const courseDataCopy = { ...courseData };
        // Get start time
        const startTimeFormatted = formattingUtils.convertMinutesToTime(classObj.time);
        // Get end time
        const endInt = classObj.time + classObj.duration;
        const endDate = moment(classObj.date).add(Math.floor(endInt/1440), 'day');
        const endTimeFormatted = formattingUtils.convertMinutesToTime(endInt % 1440);
        // Structure class data
        const classData = {
            business_class_id: classObj.id ?? getPlaceholderId(courseDataCopy['moduleData'].map(x => x.classData.map(y => y.business_class_id)).flat()),
            business_course_module_id: classObj.moduleId,
            business_class_name: classObj.name,
            business_class_description: classObj.description ? classObj.description : null,
            business_class_img: classObj.image,
            business_class_img_state: classObj.imageState,
            business_class_start: `${classObj.date.format('YYYY-MM-DD')} ${startTimeFormatted}:00`,
            business_class_end: `${endDate.format('YYYY-MM-DD')} ${endTimeFormatted}:00`,
            business_location_id: classObj.businessLocationId,
            staff_map: classObj.staffIds,
            state: classObj.id > 0 ? 2 : 1
        };
        // Update class data
        const moduleIndex = courseDataCopy['moduleData'].findIndex(x => x.business_course_module_id === classObj.moduleId);
        if(classObj.id) {
            // Update existing class
            const previousModuleIndex = courseDataCopy['moduleData'].findIndex(x => x.business_course_module_id === classObj.previousModuleId);
            const classIndex = courseDataCopy['moduleData'][previousModuleIndex]['classData'].findIndex(x => x.business_class_id === classObj.id);
            if(classObj.previousModuleId !== classObj.moduleId) {
                // Class is in a different module
                courseDataCopy['moduleData'][previousModuleIndex]['classData'].splice(classIndex, 1);
                courseDataCopy['moduleData'][moduleIndex]['classData'].push(classData);
            } else {
                // Class is in same module
                courseDataCopy['moduleData'][moduleIndex]['classData'][classIndex] = classData;
            }
        } else {
            courseDataCopy['moduleData'][moduleIndex]['classData'].push(classData);
        }
        setCourseData(courseDataCopy);
    }

    const submitCourse = async () => {
        if(loading) return;
        props.actions.loadLoadingSpinner(true);
        setLoading(true);
        try {
            await Api.putCourseData(courseData)
            props.triggerNotification('Course updated successfully.', 'success');
            await loadCourseData(false);
        } catch(e) {
            if(e?.response?.data?.message) {
                props.triggerNotification(e.response.data.message, "danger");
            } else {
                props.triggerNotification("An error occurred while updating the course. If this problem persists, please contact Styler support at support@styler.digital.", "danger");
            }
        }
        props.actions.loadLoadingSpinner(false);
        setLoading(false);
    }

    const deleteCourse = async () => {
        if(loading) return;
        setLoading(true);
        try {
            await Api.deleteCourseData(courseData.business_course_id);
            props.triggerNotification("Course deleted successfully", "success");
            navigate('/admin/courses');
            return;
        } catch(e) {
            props.triggerNotification("An error occurred while deleting the course. If this problem persists, please contact Styler support", "danger");
        }
        setLoading(false);
    }

    const deleteClass = (moduleId, classId) => {
        const courseDataCopy = { ...courseData };
        const moduleIndex = courseDataCopy['moduleData'].findIndex(x => x.business_course_module_id === moduleId);
        const classIndex = courseDataCopy['moduleData'][moduleIndex]['classData'].findIndex(x => x.business_class_id === classId);
        if(classId > 0) {
            courseDataCopy['moduleData'][moduleIndex]['classData'][classIndex].state = 3;
        } else {
            courseDataCopy['moduleData'][moduleIndex]['classData'].splice(classIndex, 1);
        }
        setCourseData(courseDataCopy);
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case 'overview':
                return (
                    <OverviewTab
                        staffData={props.staff}
                        businessLocationData={props.businessLocation}
                        moduleData={courseData['moduleData']}
                        setModuleData={(moduleData) => setCourseData({ ...courseData, moduleData })}
                        submitModule={submitModule}
                        submitClass={submitClass}
                        deleteClass={deleteClass}
                        editable={editable}
                        isXs={isXs}
                    />
                );
            case 'attendees':
                return (
                    <AttendeesTab
                        attendeeFormComplete={attendeeFormComplete}
                        attendeeFormSubmit={attendeeFormSubmit}
                        attendeeSubmit={attendeeSubmit}
                        attendeeDelete={attendeeDelete}
                        attendeeCapacity={courseData?.business_course_attendee_limit}
                        attendeeData={courseData?.attendeeData.filter(x => x.state !== 3)}
                        inviteOnly={courseData?.business_course_attendee_invite_only}
                        editable={editable}
                    />
                );
            case 'pricing':
                return (
                    <PricingTab
                        isPaid={courseData.business_course_payment_required}
                        onIsPaidChange={(e) => setCourseData({ ...courseData, business_course_payment_required: e.target.value })}
                        price={courseData.business_course_payment_amount}
                        onPriceChange={(value) => setCourseData({ ...courseData, business_course_payment_amount: value })}
                        currencySymbol={currencySymbol}
                        editable={editable}
                    />
                );
            case 'settings':
                return (
                    <SettingsTab
                        title={courseData.business_course_name}
                        updateTitle={updateCourseTitle}
                        description={courseData.business_course_description}
                        updateDescription={updateCourseDescription}
                        image={courseData.business_course_img}
                        updateImage={updateCourseImage}
                        colour={courseData.business_course_colour}
                        updateColour={updateCourseColour}
                        published={courseData.published}
                        updatePublished={updateCoursePublished}
                        editable={editable}
                    />
                );
            default:
                return <Box>Overview</Box>;
        }
    }

    return (
        <>
            <PanelHeader size="sm"/>
            <div className="content">
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={11} lg={8} xl={6}>
                        {courseData && (
                            <Card>
                                <CourseHeader
                                    image={courseData.business_course_img}
                                    title={courseData.business_course_name}
                                    updateTitle={updateCourseTitle}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    onSave={submitCourse}
                                    onDelete={() => setDeleteCourseModalVisible(true)}
                                    loading={loading}
                                    editable={editable}
                                    deletable={deletable}
                                    isXs={isXs}
                                />
                                <Divider sx={{ backgroundColor: 'gray', my: 2 }} />
                                {renderTabContent()}
                                {isXs && (
                                    <React.Fragment>
                                        <Divider sx={{ backgroundColor: 'gray', mt: 3, mb: 1 }} />
                                        <LoadingButton
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            sx={{ mt: 2 }}
                                            onClick={submitCourse}
                                            loading={loading}
                                            disabled={!editable}
                                        >
                                            Save
                                        </LoadingButton>
                                    </React.Fragment>
                                )}
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </div>
            {deleteCourseModalVisible && (
                <ConfirmDeleteModal
                    isVisible={true}
                    setVisible={() => setDeleteCourseModalVisible(false)}
                    onConfirm={deleteCourse}
                    loading={loading}
                    dataEntityName={courseData?.business_course_name}
                />
            )}
        </>
    );
}

export default Course;